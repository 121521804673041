import { isMobile, isTablet } from '../services/userDevice';
const imageVariants = {
    mobile: 'w=645',
    tablet: 'w=1152',
    default: 'public',
};

const imageModifiers = {
    placeholder: 'w=100,q=1,blur=50',
    logo: 'w=100',
};

function getImageByVariant(image, modifier = null) {
    if (modifier) {
        return image.replace('${variant}', imageModifiers.modifier);
    }

    if (isMobile()) {
        return image.replace('${variant}', imageVariants.mobile);
    }

    if (isTablet()) {
        return image.replace('${variant}', imageVariants.tablet);
    }

    return image.replace('${variant}', imageVariants.default);
}

export { getImageByVariant };
