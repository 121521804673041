import { searchAnuncioService } from '@/infrastructure/services/searchAnuncioService';
import store from '@/infrastructure/persistence/store/vuex';
async function searchSimilarAnuncio(query) {
    const vuexstore = store.repository;
    try {
        vuexstore.dispatch('similarAds/clear');
        var response = await searchAnuncioService(query);
        var filteredAds = [];
        response['data'].forEach((ad, key) => {
            if (key > 6) {
                response['data'].splice(key, 1);
            } else {
                if (filteredAds[ad.price] === ad.title) {
                    response['data'].splice(key, 1);
                } else {
                    filteredAds[ad.price] = ad.title;
                }
            }
        });
        vuexstore.dispatch('similarAds/setSimilarAds', response['data']);
        return response['data'];
    } catch (error) {
        vuexstore.dispatch('similarAds/clear');
        throw Error(error.message);
    }
}

export { searchSimilarAnuncio };
