import { getApiParserBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

const DEFAULT_MIN_PRICE = 750;
const DEFAULT_MAX_PRICE = 200000;
const DEFAULT_MIN_YEAR = 1970;
const DEFAULT_MAX_YEAR = new Date().getFullYear();
const DEFAULT_MIN_MILEAGE = 0;
const DEFAULT_MAX_MILEAGE = 950000;
const DEFAULT_MIN_POWER = 0;
const DEFAULT_MAX_POWER = 1200;
const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 20;

async function getSearchHistory() {
    try {
        const historyResponse = await HttpClient.get(`${getApiParserBaseUrl()}/user/searches`);
        return historyResponse.data['data'];
    } catch (err) {
        throw err;
    }
}

function parseSearch(search) {
    let parsedSearch = {
        make: search.make,
        model: search.model,
        date: search.date,
        province: search.province,
        geartype: search.geartype,
        fueltype: search.fueltype,
        minprice: search.minprice,
        maxprice: search.maxprice,
        minyear: search.minyear,
        maxyear: search.maxyear,
        minmileage: search.minmileage,
        maxmileage: search.maxmileage,
        minpower: search.minpower,
        maxpower: search.maxpower,
        page: search.page,
        perpage: search.perpage,
    };
    return parseDefaults(parsedSearch);
}

function parseDefaults(search) {
    let parsedSearch = {
        make: search.make ?? null,
        model: search.model ?? null,
        date: search.date ?? null,
        province: search.province ?? null,
        geartype: search.geartype ?? null,
        fueltype: search.fueltype ?? null,
        minprice: search.minprice ?? DEFAULT_MIN_PRICE,
        maxprice: search.maxprice ?? DEFAULT_MAX_PRICE,
        minyear: search.minyear ?? DEFAULT_MIN_YEAR,
        maxyear: search.maxyear ?? DEFAULT_MAX_YEAR,
        minmileage: search.minmileage ?? DEFAULT_MIN_MILEAGE,
        maxmileage: search.maxmileage ?? DEFAULT_MAX_MILEAGE,
        minpower: search.minpower ?? DEFAULT_MIN_POWER,
        maxpower: search.maxpower ?? DEFAULT_MAX_POWER,
        page: search.page ?? DEFAULT_PAGE,
        perpage: search.perpage ?? DEFAULT_PER_PAGE,
    };

    return parsedSearch;
}

export { getSearchHistory, parseSearch };
