export default {
    namespaced: true,
    state: () => {
        return {
            items: [],
        };
    },
    mutations: {
        setSimilarAds(state, value) {
            state.items = value;
        },
        clearAds(state) {
            state.items = [];
        },
    },
    getters: {},
    actions: {
        setSimilarAds({ commit }, payload) {
            commit('setSimilarAds', payload);
        },
        clear({ commit }) {
            commit('clearAds');
        },
    },
};
