import {
    getValuesService,
    getValuesBySlugService,
    getValuesBySlugBasedOnParentService,
} from '@/infrastructure/services/getValuesService';
async function getFilterValues(filter_id, order_by) {
    try {
        const values = await getValuesService(filter_id, order_by);
        return values;
    } catch (error) {
        throw Error(error.message);
    }
}
async function getFilterValuesBySlug(slug) {
    try {
        const values = await getValuesBySlugService(slug);
        return values;
    } catch (error) {
        throw Error(error.message);
    }
}
async function getFilterValuesBySlugBasedOnParent(parentSlug, slug) {
    try {
        const values = await getValuesBySlugBasedOnParentService(parentSlug, slug);
        return values;
    } catch (error) {
        throw Error(error.message);
    }
}

export { getFilterValues, getFilterValuesBySlug, getFilterValuesBySlugBasedOnParent };
