import Brand from '../../views/seo/Brand.vue';
import Model from '../../views/seo/Model.vue';
import BrandProvince from '../../views/seo/BrandProvince.vue';
import ModelProvince from '../../views/seo/ModelProvince.vue';

const seoRoutes = [
    {
        path: '/coches-segunda-mano/:brand',
        name: 'brand',
        component: Brand,
    },
    {
        path: '/coches-segunda-mano/:brand/:model',
        name: 'model',
        component: Model,
    },
    {
        path: '/coches-segunda-mano/:brand/en/:province',
        name: 'brand-province',
        component: BrandProvince,
    },
    {
        path: '/coches-segunda-mano/:brand/:model/en/:province',
        name: 'brand-model-province',
        component: ModelProvince,
    },
];

export default seoRoutes;
