<template>
    <a :href="getURL" class="col-11 col-md-5 col-lg-4 col-xxl-3 m-1 mt-2 section-card p-0">
        <div class="back-container">
            <img :src="getImage" :alt="section.make + 'de segunda mano'" @error="imageUrlAlt" loading="lazy" />
            <div class="front-container">
                <h2>
                    <b>{{ section.make }}</b>
                    <br />
                    de segunda mano
                </h2>
            </div>
        </div>
    </a>
</template>
<script>
export default {
    props: ['section'],
    data() {
        return {
            showImg: true,
        };
    },
    computed: {
        getImage() {
            let idx = Math.floor(Math.random() * 5);
            return this.section.images[idx];
        },
        getURL() {
            return '/?minprice=0&maxprice=70000&make=' + this.section.makeId;
        },
    },
    methods: {
        imageUrlAlt(event) {
            event.target.src = this.section.images[Math.floor(Math.random() * 5)];
        },
    },
    setup() {},
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.section-card {
    position: relative;
    @include box-shadow;
    border-radius: $border-radius;
    height: 200px;
    transition: all 0.4s ease;
    overflow: hidden;
    vertical-align: middle;
    object-fit: cover;
    filter: grayscale(10%);
    &:hover {
        cursor: pointer;
        margin-top: -5px;
        @include card-box-shadow;
        transition: all 0.4s ease;
        filter: grayscale(0%);
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .front-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        background-color: rgb(0, 0, 0, 0.3);
        h2 {
            text-align: center;
            margin: auto;
            margin-top: 45px;
            color: $white;
        }
    }
}
</style>
