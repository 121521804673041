<template>
    <div class="container-fluid footer mt-4 bg-light" id="footer">
        <div class="container mt-4">
            <router-link :to="{ name: 'home' }" rel="dofollow">
                <div class="d-flex justify-content-center p-1 pb-0" id="footer-logo">
                    <img
                        class="logo"
                        src="@/assets/icons/ac-concept-logo-green.png"
                        alt="Logotipo de AlertaCoches.es"
                        loading="lazy"
                    />
                </div>
            </router-link>
            <hr class="underline mb-4" />

            <div class="mid-container d-flex flex-column align-items-top flex-sm-row justify-content-between">
                <div class="link-section text-center text-sm-left mb-4">
                    <h6 class="title text-bold">Sobre nosotros</h6>
                    <ul class="list-unstyled">
                        <li>
                            <a
                                name="team_information_link"
                                href="https://about.alertacoches.es/#team"
                                target="_blank"
                                rel="nofollow"
                            >
                                {{ $t('the_team') }}
                            </a>
                        </li>
                        <li>
                            <a
                                name="frequently_asked_questions_link"
                                href="https://about.alertacoches.es/#preguntas-frecuentes"
                                target="_blank"
                                rel="nofollow"
                            >
                                {{ $t('faq') }}
                            </a>
                        </li>
                        <li>
                            <a
                                name="contact_us_links"
                                href="https://about.alertacoches.es/#contacto"
                                target="_blank"
                                rel="nofollow"
                            >
                                {{ $t('contact') }}
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="link-section text-sm-left mb-4">
                    <h6 class="title text-bold">Legal</h6>
                    <ul class="list-unstyled">
                        <li>
                            <a
                                name="aviso_legal_link"
                                href="https://about.alertacoches.es/aviso-legal"
                                target="_blank"
                                rel="nofollow"
                            >
                                {{ $t('legal_policy') }}
                            </a>
                        </li>
                        <li>
                            <a
                                name="politica_privacidad_link"
                                href="https://about.alertacoches.es/politica-privacidad"
                                target="_blank"
                                rel="nofollow"
                            >
                                {{ $t('priv_policy') }}
                            </a>
                        </li>
                        <li>
                            <a
                                name="cookies_link"
                                href="https://about.alertacoches.es/politica-cookies"
                                target="_blank"
                                rel="nofollow"
                            >
                                {{ $t('cookies_policy') }}
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="link-section text-sm-left mb-4">
                    <h6 class="title text-bold">AlertaCoches Pro</h6>
                    <ul class="list-unstyled">
                        <li>
                            <a
                                name="publica_tus_vehiculos_link"
                                href="https://about.alertacoches.es/servicios/publica-tus-vehiculos"
                                target="_blank"
                                rel="dofollow"
                                alt-labels="Publica tus vehículos"
                            >
                                Publica tus vehículos
                            </a>
                        </li>
                    </ul>
                </div>

                <div>
                    <div class="social-links">
                        <a
                            name="instagram_link"
                            href="https://instagram.com/alertacoches"
                            target="_blank"
                            rel="nofollow"
                        >
                            <img
                                class="icon larger-icon"
                                src="@/assets/icons/icon-instagram.svg"
                                alt="Logo de instagram"
                                loading="lazy"
                            />
                        </a>
                        <a
                            name="facebook_link"
                            href="https://www.facebook.com/alertacoches/"
                            target="_blank"
                            rel="nofollow"
                        >
                            <img
                                class="icon larger-icon"
                                src="@/assets/icons/icon-facebook.svg"
                                alt="Logo de facebook"
                                loading="lazy"
                            />
                        </a>
                        <a
                            name="youtube_link"
                            href="https://www.youtube.com/@alertacoches"
                            target="_blank"
                            rel="nofollow"
                        >
                            <img
                                class="icon larger-icon"
                                src="@/assets/icons/icon-youtube.svg"
                                alt="Logo de YouTube"
                                loading="lazy"
                            />
                        </a>
                        <a
                            name="tiktok_link"
                            href="https://www.tiktok.com/@alertacoches"
                            target="_blank"
                            rel="nofollow"
                        >
                            <img
                                class="icon larger-icon"
                                src="@/assets/icons/icon-tik-tok.svg"
                                alt="Logo de TikTok"
                                loading="lazy"
                            />
                        </a>
                        <a
                            name="linkedin_link"
                            href="https://www.linkedin.com/company/alertacoches"
                            target="_blank"
                            rel="nofollow"
                        >
                            <img
                                class="icon larger-icon"
                                src="@/assets/icons/icon-linkedin.svg"
                                alt="Logo de LinkedIn"
                                loading="lazy"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div class="copyright-area text-center">
                <p class="text-center" style="display: inline">
                    Copyright @ AlertaCoches {{ new Date().getFullYear() }} @ {{ $t('propetary_copyright') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    components: {},
    setup() {},
    methods: {},
};
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.footer {
    font-size: 0.75em;
    border-top: 1px solid $gray-light;
    color: $gray;
    padding-bottom: 80px;
    bottom: 0;
    position: relative;
    .logo {
        height: 50px;
        width: auto;
        border-radius: 10px;
    }

    .title {
        font-weight: bold;
        font-size: 1.4em;
    }

    .underline {
        border: none;
        border-top: 2px solid $gray-2;
        width: 100%;
        margin: 0 auto;
        margin-top: 10px;
    }

    a {
        text-decoration: none;
        color: $gray;
        transition: all 0.4s ease;

        &:hover {
            color: $secondary;
            transition: all 0.4s ease;
        }
    }

    .larger-icon {
        margin: 7px;
        width: 25px;
        height: 25px;
        &:hover {
            color: $primary;
            transition: all 0.4s ease;
            transform: scale(1.2);
        }
    }

    .link-section {
        ul {
            list-style: none;
            padding: 0;

            li {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }

    .copyright-area {
        margin-top: 20px;
        margin-bottom: 10px;

        p {
            font-size: 0.8em;
            margin: 0;
        }
    }
}
</style>
