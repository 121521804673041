export default {
    namespaced: true,
    state: () => {
        return {
            succesfulResponse: null,
            errorResponse: null,
        };
    },
    mutations: {
        setSuccesfulResponse(state, value) {
            state.succesfulResponse = value;
        },
        setErrorResponse(state, value) {
            state.errorResponse = value;
        },
    },
    getters: {},
    actions: {
        setSuccesfulResponse({ commit }, payload) {
            commit('setSuccesfulResponse', payload);
        },
        setErrorResponse({ commit }, payload) {
            commit('setErrorResponse', payload);
        },
    },
};
