export default {
    namespaced: true,
    state: () => {
        return {
            items: [],
            loading: false,
            errorFlag: false,
            initialSearch: true,
            lastPage: false,
            quickReturn: false,
        };
    },
    mutations: {
        setAds(state, value) {
            state.items = value;
        },
        clearAds(state) {
            state.items = [];
        },
        setErrorFlag(state, value) {
            state.errorFlag = value;
        },
        setLoading(state, value) {
            state.loading = value;
        },
        setInitialSearch(state, value) {
            state.initialSearch = value;
        },
        setLastPage(state, value) {
            state.lastPage = value;
        },
        setQuickReturn(state, value) {
            state.quickReturn = value;
        },
    },
    getters: {
        isLoading(state) {
            return state.loading;
        },
        getAds(state) {
            return state.items;
        },
        noResults(state) {
            return state.items.length === 0 && !state.loading;
        },
    },
    actions: {
        setAds({ commit }, payload) {
            commit('setAds', payload);
        },
        clear({ commit }) {
            commit('clearAds');
        },
        setLoading({ commit }, payload) {
            commit('setLoading', payload);
        },
        setErrorFlag({ commit }, payload) {
            commit('setErrorFlag', payload);
        },
        setInitialSearch({ commit }, payload) {
            commit('setInitialSearch', payload);
        },
        setLastPage({ commit }, payload) {
            commit('setLastPage', payload);
        },
        setQuickReturn({ commit }, payload) {
            commit('setQuickReturn', payload);
        },
    },
};
