export default {
    'es-ES': {
        currency: {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
            maximumFractionDigits: 2,
        },
    },
    'en-GB': {
        currency: {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
            maximumFractionDigits: 2,
        },
    },
};
