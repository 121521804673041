function filterNulls(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => filterNulls(item)).filter((item) => item !== null);
    }

    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = filterNulls(obj[key]);
            if (value !== null) {
                result[key] = value;
            }
        }
    }

    return result;
}

export { filterNulls };
