<template>
    <template v-if="this.photoArray.length > 0 && this.photoArray[0]">
        <div class="container-fluid p-0" id="photo-gallery-container">
            <div class="container-fluid p-0 main-photo-area" id="main-photo-area">
                <div class="container p-0">
                    <template v-if="this.photoArray.length < 1">
                        <span>{{ this.highlightImageNr }}/{{ this.photoArray.length }}</span>
                    </template>
                    <div id="main-photo-container" class="row w-100 main-photo mb-2 parallax-slider">
                        <img
                            id="hightlighted-photo"
                            :src="this.imageNavSwitch ? this.highlightedImageUrl : this.photoArray[0]"
                            :alt="`Se muestra en grande la foto número ${this.highlightImageNr} de ${this.photoArray.length}`"
                            @error="handleImageError"
                        />
                    </div>
                </div>
            </div>
            <template v-if="this.photoArray.length > 1">
                <div class="container-fluid" id="preview-photo-area">
                    <div class="container p-0 photo-carousel">
                        <div class="row p-0" id="preview-photo-carousel" ref="preview-photo-carousel">
                            <template v-for="(photo, key) in photoArray" v-bind:key>
                                <img
                                    :class="`photo_preview_id_${key}` === this.highlightedImageId ? 'highlighted' : ''"
                                    :src="photo"
                                    :name="`Foto número ${key + 1}`"
                                    :alt="`Vista previa de la foto número ${key + 1}`"
                                    :data-photo-idx="key"
                                    :id="`photo_preview_id_${key}`"
                                    v-on:click="highlightPhoto"
                                    @error="disablePreview"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </template>

    <template v-else>
        <template v-if="!this.isLoading">
            <div class="container-fluid p-0" id="photo-gallery-container">
                <div class="container-fluid p-0 main-photo-area" id="main-photo-area">
                    <div class="container p-0">
                        <div id="main-photo-container" class="row w-100 main-photo mb-2 parallax-slider">
                            <img
                                id="hightlighted-photo"
                                src="@/assets/icons/no-picture.webp"
                                alt="Este anuncio no tiene imágenes"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="container-fluid p-0" id="photo-gallery-container">
                <div class="container-fluid p-0 skeleton-main-photo-area" id="main-photo-area">
                    <div class="container p-0">
                        <span>
                            <div></div>
                            <div class="bg-transparent m-0 p-0"></div>
                            <div></div>
                        </span>
                        <div class="row w-100 skeleton-main-photo mb-2">
                            <div class="skeleton-img">
                                <img src="@/assets/icons/image-icon.svg" alt="Icono de imagen" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" id="preview-photo-area">
                    <div class="container p-0 skeleton-photo-carousel">
                        <div class="row p-0" id="preview-photo-carousel" ref="preview-photo-carousel">
                            <template v-for="i in 12" :key="i">
                                <div class="skeleton_photo_preview">
                                    <img src="@/assets/icons/image-icon.svg" alt="Icono de imagen" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </template>
</template>

<script>
import noPicturePlaceholder from '@/assets/icons/no-picture.webp';

export default {
    props: ['photoArray'],
    data() {
        return {
            isLoading: true,
            highlightedImageId: 'photo_preview_id_0',
            highlightedImageUrl: this.photoArray[0],
            highlightedImageIdx: 0,
            highlightImageNr: 1,
            imageNavSwitch: false,
            transition: false,
        };
    },
    methods: {
        highlightPhoto: function (event) {
            event.preventDefault();
            if (this.highlightedImageId !== event.target.getAttribute('id')) {
                this.highlightedImageId = event.target.getAttribute('id');
                this.highlightedImageIdx = parseInt(event.target.getAttribute('data-photo-idx'));
                this.highlightImageNr = this.highlightedImageIdx + 1;

                var mainPhotoArea = document.getElementById('main-photo-container');
                mainPhotoArea.classList.add('transitioning-src');
                setTimeout(() => {
                    this.highlightedImageUrl = this.photoArray[this.highlightedImageIdx];
                    mainPhotoArea.classList.remove('transitioning-src');
                    this.transition = false;
                }, 100);
                this.imageNavSwitch = true;

                this.centerElement(event);
            }
        },
        centerElement(event) {
            const eventElementWidth = event.target.clientWidth + 10;
            const elementContainer = event.target.parentElement;
            const images = Array.from(elementContainer.querySelectorAll('img'));
            var marginFromLeft = 0;
            images.forEach((photoPreview) => {
                if (
                    parseInt(photoPreview.getAttribute('data-photo-idx')) <
                    parseInt(event.target.getAttribute('data-photo-idx'))
                ) {
                    marginFromLeft += photoPreview.clientWidth + 10;
                }
            });
            const elementContainerWidth = event.target.parentElement.clientWidth;
            elementContainer.scrollLeft = marginFromLeft - elementContainerWidth / 2 + eventElementWidth / 2;
        },
        resetState() {
            this.highlightedImageId = 'photo_preview_id_0';
            this.highlightedImageUrl = this.photoArray[0];
            this.highlightedImageIdx = 0;
            this.highlightImageNr = 1;
            this.imageNavSwitch = false;

            let elmnt = document.getElementById('preview-photo-carousel');
            if (elmnt) {
                elmnt.scrollLeft = 0;
            }
        },
        imageUrlAlt(event) {
            let picIdx = event.target.getAttribute('data-image-id');
            if (picIdx < 10) {
                picIdx++;
                event.target.setAttribute('data-image-id', picIdx);
                event.target.src = this.card.images[picIdx];
            } else {
                event.target.src = '/src/assets/icons/car.png';
                event.target.alt = 'Icono de coche: el anuncio no tiene imagenes.';
            }
        },
        getCorrectSizedImage(imageUrl, source) {
            switch (source) {
                case 2:
                    return imageUrl.replace('pictureSize=W800', 'pictureSize=W100');
                case 8:
                    if (imageUrl === 'https://images.coches.com/_vo_/default.pngp=cc_vo_medium') {
                        return 'https://images.coches.com/_vo_/default.png?p=cc_vo_medium';
                    }
                    return imageUrl + '?p=cc_vo_medium';
                default:
                    return imageUrl;
            }
        },
        handleImageError(event) {
            event.target.src = noPicturePlaceholder;
        },
        disablePreview(event) {
            event.target.style.display = 'none';
        },
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 100);
    },
    watch: {
        $route(to, from) {
            this.resetState();
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/main.scss';
.main-photo-area {
    span {
        position: absolute;
        float: right;
        right: 10px;
        margin-top: 10px;
        padding: 2px 5px 0px 5px;
        background-color: $white;
        height: fit-content;
        width: fit-content;
        z-index: 10;
        border-radius: $border-radius;
        font-size: 0.8em;
    }
    .row {
        padding: 0;
        margin: 00px auto;
    }
    .main-photo {
        height: 350px;
        img {
            position: relative;
            object-fit: cover;
            width: 100%;
            height: 100%;
            margin: 0px auto;
            padding: 0;
            border-radius: $border-radius;
        }
    }
}
.photo-carousel > .row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}
.photo-carousel > .row > img {
    display: inline-block;
    height: 80px;
    width: auto;
    margin: 0px 5px !important;
    filter: grayscale(90%);
    -webkit-filter: grayscale(90%);
    -moz-filter: grayscale(90%);
    border-radius: $border-radius;
    padding: 0;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
    cursor: pointer;
    align-items: flex-start;
}
.skeleton-main-photo-area {
    span {
        position: absolute;
        float: right;
        right: 10px;
        margin-top: 10px;
        padding: 2px 5px 0px 5px;
        background-color: $white;
        height: fit-content;
        width: fit-content;
        z-index: 10;
        border-radius: $border-radius;
        font-size: 0.8em;
        color: $gray-10;
        justify-content: space-around;
        -webkit-animation: ease flickerAnimation 1s infinite;
        -moz-animation: ease flickerAnimation 1s infinite;
        -o-animation: ease flickerAnimation 1s infinite;
        animation: ease flickerAnimation 1s infinite;
        div {
            position: relative;
            float: left;
            height: 20px;
            width: 10px;
            background-color: transparent;
            margin-bottom: 2px;
            border-radius: $border-radius;
        }
    }
    .row {
        padding: 0;
        margin: 0px auto;
    }
    .skeleton-main-photo {
        height: 350px;
        .skeleton-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            margin: 0px auto;
            padding: 0;
            text-decoration: none !important;
            background-color: $gray-10;
            -webkit-animation: ease flickerAnimation 1s infinite;
            -moz-animation: ease flickerAnimation 1s infinite;
            -o-animation: ease flickerAnimation 1s infinite;
            animation: ease flickerAnimation 1s infinite;
            img {
                position: relative;
                height: 50px;
                width: 50px;
                margin: auto;
                top: 50%;
                margin-top: -30px;
            }
        }
    }
}
.skeleton-photo-carousel > .row {
    display: block;
    overflow-x: hidden;
    white-space: nowrap;
    -webkit-box-shadow: inset -89px 0px 85px -35px rgba(255, 255, 255, 0.9);
    -moz-box-shadow: inset -89px 0px 85px -35px rgba(255, 255, 255, 0.9);
    box-shadow: inset -89px 0px 85px -35px rgba(255, 255, 255, 0.9);
    scroll-behavior: smooth;
    -webkit-animation: ease flickerAnimation 1s infinite;
    -moz-animation: ease flickerAnimation 1s infinite;
    -o-animation: ease flickerAnimation 1s infinite;
    animation: ease flickerAnimation 1s infinite;
}
.skeleton-photo-carousel > .row > .skeleton_photo_preview {
    display: inline-block;
    height: 80px !important;
    width: 120px !important;
    margin: 0px 5px !important;
    border-radius: $border-radius;
    padding: 0;
    align-items: flex-start;
    background-color: $gray-10;
    img {
        position: relative;
        height: 32px;
        width: 32px;
        margin: auto;
        top: 50%;
        margin-top: -21px;
    }
}
.highlighted {
    filter: grayscale(0%) !important;
    -webkit-filter: grayscale(0%) !important;
    -moz-filter: grayscale(0%) !important;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}
.parallax-slider {
    transition: opacity 0.1s ease-in;
    -webkit-transition: opacity 0.1s ease-in;
    -moz-transition: opacity 0.1s ease-in;
    -ms-transition: opacity 0.1s ease-in;
    -o-transition: opacity 0.1s ease-in;
    opacity: 1;
}
.transitioning-src {
    transition: opacity 0.1s ease-out;
    -webkit-transition: opacity 0.1s ease-out;
    -moz-transition: opacity 0.1s ease-out;
    -ms-transition: opacity 0.1s ease-out;
    -o-transition: opacity 0.1s ease-out;
    opacity: 0;
}
@media (max-width: $breakpoint-sm) {
    .main-photo-area {
        .main-photo {
            height: 350px;
        }
    }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .main-photo-area {
        .container {
            padding-top: 10px !important;
            .main-photo {
                height: 400px;
                width: 100% !important;
            }
        }
    }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .main-photo-area {
        .container {
            padding-top: 10px !important;
            .main-photo {
                width: 80% !important;
                height: 425px;
            }
        }
    }
}

@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
    .main-photo-area {
        .container {
            padding-top: 10px !important;
            .main-photo {
                height: 450px;
                width: 70% !important;
            }
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .main-photo-area {
        .container {
            padding-top: 10px !important;
            .main-photo {
                height: 500px;
                width: 70% !important;
            }
        }
    }
}
</style>
