import { getApiParserBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

async function addFavourite(id) {
    try {
        const addResponse = await HttpClient.post(`${getApiParserBaseUrl()}/user/favourite/${id}`);
        return addResponse;
    } catch (err) {
        throw err;
    }
}

async function removeFavourite(id) {
    try {
        const removeResponse = await HttpClient.delete(`${getApiParserBaseUrl()}/user/favourite/${id}`);
        return removeResponse;
    } catch (err) {
        throw err;
    }
}

async function getFavourites() {
    try {
        const favouritesResponse = await HttpClient.get(`${getApiParserBaseUrl()}/user/favourites`);

        return favouritesResponse.data;
    } catch (err) {
        throw err;
    }
}

export { addFavourite, removeFavourite, getFavourites };
