import { searchAnuncioService } from '@/infrastructure/services/searchAnuncioService';
import { getCollabResource } from '@/application/getCollabResource/getCollabResource';
import { filterNulls } from '@/infrastructure/services/helperService';

import store from '@/infrastructure/persistence/store/vuex';

async function searchAnuncio(query, makeList = null, modelList = null, provinceList = null) {
    const vuexstore = store.repository;
    const filteredQuery = filterNulls(query);

    try {
        if (filteredQuery.page === 1) {
            vuexstore.dispatch('ads/clear');
            var ads = [];
        } else {
            var ads = vuexstore.state['ads'].items;
            vuexstore.dispatch('ads/setQuickReturn', true);
        }

        const response = await searchAnuncioService(filteredQuery, vuexstore.getters['user/isAuthenticated']);

        if (response['data'].length < filteredQuery.perpage) {
            vuexstore.dispatch('ads/setLastPage', true);
        }

        // if (!filteredQuery.sourceId) {
        //     let collabAdCardData = {
        //         type: 'native',
        //     };
        //     const collabAd = await getCollabResource(collabAdCardData);
        //     const collabAdParsed = await parseCollabAd(collabAd, filteredQuery, makeList, modelList, provinceList);
        //     const collabAdIdx = getRandomIdx(response['data'].length);
        //     response['data'].splice(collabAdIdx, 0, collabAdParsed);
        // }

        const completeListOfAds = ads.concat(response['data']);

        if (filteredQuery.page % 3 === 0) {
            let collabAdCardBannerData = {
                type: 'native_banner',
            };
            const collabBannerAd = await getCollabResource(collabAdCardBannerData);
            const collabBannerAdParsed = await parseCollabAd(
                collabBannerAd,
                filteredQuery,
                makeList,
                modelList,
                provinceList,
            );
            completeListOfAds.push(collabBannerAdParsed);
        }

        vuexstore.dispatch('ads/setAds', completeListOfAds);
    } catch (error) {
        vuexstore.dispatch('ads/clear');
        throw Error(error.message);
    }
}

function getRandomIdx(arrayLength) {
    return Math.floor(Math.random() * (arrayLength - 0 + 1) + 0);
}

async function parseCollabAd(collabInfo, search, makeList = null, modelList = null, provinceList = null) {
    var parsedCollabAd = {
        isCollabAd: true,
        title: collabInfo.title,
        link: collabInfo.buttonUrl,
        price: collabInfo.paragraph || null,
        images: [collabInfo.pictureSrc],
        customerName: collabInfo.customerName,
        contentId: collabInfo.contentId,
        designId: collabInfo.designId,
    };
    if (search.make !== null && makeList !== null) {
        const make = getShortDesc(search.make, makeList);
        if (make) {
            parsedCollabAd.make = make;
        }
    }
    if (search.model !== null && modelList !== null) {
        const model = getShortDesc(search.model, modelList);
        if (model) {
            parsedCollabAd.model = model;
        }
    }
    if (search.province !== null && provinceList !== null) {
        const province = getShortDesc(search.province, provinceList);
        if (province) {
            parsedCollabAd.province = province;
        }
    }
    return parsedCollabAd;
}

function getShortDesc(id, haystack) {
    let obj = haystack.find((o) => o.value_id === id);
    return obj ? obj.short_desc : false;
}

export { searchAnuncio };
