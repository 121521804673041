<template>
    <div class="page" ref="login">
        <SimpleNavBar></SimpleNavBar>
        <div class="container-fluid">
            <div class="container col-12 col-md-8 col-xl-6 col-xxl-4 login-form login-components">
                <h1 class="text-left login-header">¡Inicia sesión y encuentra tu futuro coche!</h1>
                <v-form @submit.prevent id="login-form">
                    <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="Correo electrónico"
                        theme="light"
                        variant="solo"
                        class="mb-2"
                    ></v-text-field>
                    <v-text-field
                        type="password"
                        v-model="password"
                        :rules="passwordRules"
                        label="Contraseña"
                        theme="light"
                        variant="solo"
                        class="mb-2"
                    ></v-text-field>
                    <v-btn type="submit" block class="mt-2 mb-2 btn btn-primary" @click="login">Iniciar sesión</v-btn>

                    <router-link :to="{ name: 'forgot.password' }">
                        <span class="btn btn-tertiary">¿Has olvidado la contraseña?</span>
                    </router-link>
                    <hr />

                    <router-link :to="{ name: 'register' }">
                        <v-btn block class="mt-3 btn btn-secondary">Crear cuenta</v-btn>
                    </router-link>
                </v-form>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/structural/Footer.vue';
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import { login } from '@/application/auth/login';
import { validate } from '@/infrastructure/services/validator';

export default {
    components: {
        SimpleNavBar,
        Footer,
    },
    data() {
        return {
            email: '',
            password: '',
            emailRules: validate.email,
            passwordRules: validate.password,
        };
    },
    methods: {
        async login() {
            const { email, password } = this;

            if (!email || !password) return;

            let response = await login({
                email,
                password,
            });

            if (response) {
                this.$router.push({ name: 'home' });
            }
        },
    },
    beforeMount() {
        if (this.$store.getters['user/isAuthenticated']) {
            this.$router.push({ name: 'home' });
        }
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.login-form {
    padding-top: 150px;
    padding-bottom: 150px;
    border-radius: $border-radius-deep;
    .login-header {
        font-family: $main-font;
        font-weight: bold;
        font-size: 1.2rem !important;
        color: $gray-2;
        margin-bottom: 25px;
        letter-spacing: 0.7px;
    }
}
.login-components {
    .v-field {
        height: 66px !important;
        font-size: 13px;
        border-radius: $border-radius-deep !important;
    }
    button {
        letter-spacing: 0.5px;
        font-size: 13px;
        height: 66px !important;
        border-radius: $border-radius;
        span {
            margin: 1px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .btn-primary {
        background-color: $secondary;
        color: $white;
    }
}
</style>
