<template>
    <div class="col-12 mb-3">
        <div class="col-12 search-card"></div>
    </div>
</template>

<script>
export default {
    props: ['search'],
};
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.search-card {
    position: relative;
    border-radius: $border-radius-deep;
    height: 150px;
    text-decoration: none !important;
    background-color: $gray-20;
    img {
        position: relative;
        height: 80px;
        width: 80px;
        object-fit: cover;
    }
    .caption-container {
        overflow: hidden;
        min-height: 50px;
        text-align: left;
        background-color: transparent;
        .an-title {
            width: 100%;
            font-size: 0.9em !important;
            color: $secondary;
            margin: 0px;
            margin-bottom: 2px;
            font-weight: bold;
        }
        .an-price {
            position: relative;
            float: left;
            text-align: left;
            width: 50%;
            height: 25px;
            margin-bottom: 2px;
            color: $tertiary;
            font-size: 1.05em !important;
            font-weight: bold;
        }
        .an-source {
            position: relative;
            float: right;
            text-align: center;
            width: 50%;
            height: 25px;
            margin-bottom: 2px;
            color: $secondary;
            font-size: 0.8em !important;
            font-weight: bold;
            vertical-align: bottom;
            border: 1px solid $primary;
            border-radius: $border-radius;
            padding-top: 3px;
        }
        .an-paragraph {
            width: 100%;
            margin-bottom: 2px;
            color: $tertiary;
            font-size: 0.81em !important;
            font-weight: bold;
        }
        .an-location {
            width: 100%;
            height: 20px;
            font-size: 0.8em !important;
            color: $secondary;
            vertical-align: middle;
            padding: 2px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            img {
                width: auto;
                height: 16px;
                margin-top: -4px;
            }
        }
        .an-collab-tag {
            width: 100%;
            height: 20px;
            font-size: 0.8em !important;
            font-weight: bold;
            color: $gray-2;
            vertical-align: middle;
            padding: 2px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    //xs
    @media (max-width: $breakpoint-xs) {
    }
    //sm
    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    }
    //md
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    }
    //lg
    @media (min-width: $breakpoint-md) {
    }
}
</style>
