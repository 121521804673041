import { searchLastFoundService } from '@/infrastructure/services/searchAnuncioService';
import { filterNulls } from '@/infrastructure/services/helperService';
import store from '@/infrastructure/persistence/store/vuex';
async function searchLastFound(query) {
    const vuexstore = store.repository;
    try {
        const filteredQuery = filterNulls(query);
        const ads = await searchLastFoundService(filteredQuery);
        vuexstore.dispatch('ads/setAds', ads['data']);
        return ads;
    } catch (error) {
        vuexstore.dispatch('ads/clear');
        throw Error(error.message);
    }
}

export { searchLastFound };
