import Business from '../../views/business/simple.vue';
// import BusinessSharp from '../../views/business/sharpen.vue';

const businessRoutes = [
    // {
    //     path: '/compraventa/octopus-cars',
    //     name: 'business',
    //     component: BusinessSharp,
    //     meta: {
    //         scrollTop: 0,
    //         buttonPositionIndex: 100,
    //     },
    // },
    {
        path: '/:businessType/:slug',
        name: 'business',
        component: Business,
        meta: {
            scrollTop: 0,
            buttonPositionIndex: 100,
        },
    },
];

export default businessRoutes;
