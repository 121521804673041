<template>
    <div class="container-fluid p-0 heading-section skeleton mb-4">
        <div class="container overimage p-0">
            <div class="row p-2 pb-0" id="heading-logo-skeleton">
                <img
                    class="heading-logo"
                    src="@/assets/icons/ac-concept-logo-wht50.png"
                    alt="Logotipo de AlertaCoches.es"
                />
            </div>
            <div class="row p-2 pt-0" id="heading-caption-skeleton">
                <div></div>
            </div>
            <div
                class="main-search-toggle-container w-100 fluid"
                style="margin-top: 90px !important; color: #aaa !important; cursor: pointer"
            >
                <v-row id="main_search_toggle" class="main-search-toggle rounded-xl">
                    <div id="simple_search_toggle" class="simple-search-toggle">
                        <div class="icon">
                            <v-icon icon="mdi-magnify" size="small"></v-icon>
                        </div>
                        <p id="brand_model_display" style="background-color: #bbb; height: 15px"></p>
                        <p id="year_display" style="background-color: #ddd; height: 15px; width: 200px"></p>
                    </div>
                </v-row>
            </div>
        </div>
        <div class="container p-0 heading-picture" id="heading-picture-skeleton"></div>
    </div>
    <ActionBar ref="actionBar" />
</template>
<script>
import ActionBar from '@/components/structural/ActionBar.vue';
export default {
    name: 'HeadingSectionSkeleton',
    components: {
        ActionBar,
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
#main-search-box {
    margin: 0px auto;
}
.heading-section {
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 210px;
}
.skeleton {
    text-decoration: none !important;
    background-color: transparent;
    -webkit-animation: ease flickerAnimation 1s infinite;
    -moz-animation: ease flickerAnimation 1s infinite;
    -o-animation: ease flickerAnimation 1s infinite;
    animation: ease flickerAnimation 1s infinite;
}
//xs
@media (max-width: $breakpoint-xs) {
}
//sm
@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
}
//md
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .heading-section {
        height: 230px;
    }
}
//lg
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .heading-section {
        height: 250px;
    }
}
@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
}
@media (min-width: $breakpoint-xl) {
    .heading-section {
        height: 280px;
    }
}
</style>
