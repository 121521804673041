<template>
    <div class="container" :style="`font-family: ${this.brandStyle.fontFamily}`">
        <div class="row">
            <div class="col-12 col-md-10 mx-auto mt-6">
                <h2 :style="`color: ${this.customer.primaryColor}`">Confía en nuestros clientes</h2>
                <p>
                    La satisfacción y la experiencia de los clientes es nuestra prioridad. Lee lo que opinan y conoce
                    nuestra historia.
                </p>
                <p v-if="this.reviews.length < 1">
                    <b>Todavía no hay opiniones. ¡Sé el primero en dejar la tuya!</b>
                </p>
                <v-slide-group class="p-2" v-else>
                    <v-slide-group-item v-for="review in reviews" :key="review">
                        <v-card
                            class="m-2 mr-3 mb-4 review-card"
                            height="fit-content"
                            max-width="270"
                            prepend-icon="mdi-format-quote-open"
                            rounded="xl"
                            :style="`color: ${this.brandStyle.priceColor}`"
                            elevation="3"
                        >
                            <template v-slot:prepend>
                                <v-icon size="x-large"></v-icon>
                            </template>
                            <v-card-text
                                class="text-medium-emphasis text-darken-3 mt-3 testimonial-collapsed"
                                :id="`testimonial-${review.id}`"
                            >
                                {{ review.testimonial }}
                            </v-card-text>

                            <v-btn
                                v-bind:append-icon="isCollapsed(review) ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                                class="text-capitalize"
                                v-bind:class="isShortTestimonial(review.testimonial)"
                                :style="`color: ${this.brandStyle.priceColor}`"
                                :text="isCollapsed(review) ? 'Leer más' : 'Leer menos'"
                                variant="tonal"
                                size="small"
                                rounded
                                @click="toggleCollapse(review)"
                            ></v-btn>
                            <v-card-actions :style="`color: ${this.brandStyle.titleColor}`">
                                <v-list-item class="w-100">
                                    <template v-slot:prepend>
                                        <v-avatar color="grey-darken-3" :image="review.image"></v-avatar>
                                    </template>

                                    <v-list-item-title class="text-left font-weight-bold">
                                        {{ review.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <div class="text-left">
                                            <v-rating
                                                v-model="review.rating"
                                                :active-color="`${this.brandStyle.titleColor}`"
                                                :color="`${this.brandStyle.titleColor}`"
                                                density="compact"
                                                readonly
                                            ></v-rating>
                                        </div>
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </v-card-actions>
                        </v-card>
                    </v-slide-group-item>
                </v-slide-group>
            </div>
        </div>
    </div>
</template>

<script>
import { getCustomerReviews } from '@/application/business/getCustomerReviews';

export default {
    name: 'CustomerReviews',
    props: {
        customer: {
            type: Object,
            required: true,
        },
        brandStyle: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            reviews: [],
        };
    },
    methods: {
        isShortTestimonial(testimonial) {
            return testimonial.length < 175 ? 'opacity-0' : '';
        },
        isCollapsed(review) {
            return review.isCollapsed;
        },
        toggleCollapse(review) {
            const testimonial = document.getElementById(`testimonial-${review.id}`);
            testimonial.classList.toggle('testimonial-collapsed');
            review.isCollapsed = !review.isCollapsed;
        },
    },
    async created() {
        this.reviews = await getCustomerReviews(this.customer.slug);

        this.reviews.forEach((review) => {
            review.isCollapsed = true;
        });
    },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
p {
    color: #666;
    font-size: 1em;
}
.review-card {
    .v-card-text {
        min-height: 120px;
        max-height: 600px;
        transition: all 0.7s ease;
        overflow: hidden;
        font-style: italic;
    }
}
.testimonial-collapsed {
    max-height: 120px !important;
    overflow: hidden;
    margin-bottom: 10px;
    transition: all 0.7s ease;
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    p {
        font-size: 1em;
    }
    h2 {
        margin-left: 0px;
    }
}
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    p {
        font-size: 1.2em;
    }
    h2 {
        margin-left: 0px;
    }
}
</style>
