import DeviceDetector from 'device-detector-js';
const deviceDetector = new DeviceDetector();

function isMobile() {
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    return device.device.type === 'smartphone';
}

function isTablet() {
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    return device.device.type === 'tablet';
}

function isDesktop() {
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    return device.device.type === 'desktop';
}

export { isMobile, isTablet, isDesktop };
