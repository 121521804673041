export default [
    {
        designId: 'native_banner',
        contentId: 'eres_compraventa_o_concesionario',
        title: 'Empieza a vender más coches',
        paragraph: 'Publica tus vehículos en AlertaCoches de forma automática y con tu propio diseño',
        pictureSrc: 'https://imagedelivery.net/F646Wun-eua00pA0NmkORQ/1400945f-3a2b-4fce-d4db-e29de9b7f400/public',
        customerName: 'AlertaCoches',
        buttonUrl: 'https://about.alertacoches.es/servicios/publica-tus-vehiculos',
    },
    {
        designId: 'native_banner',
        contentId: 'quieres_mostrar_stock',
        title: 'Muestra tus vehículos a miles de potenciales compradores',
        paragraph: 'Publica tus vehículos en AlertaCoches de forma automática y con tu propio diseño',
        pictureSrc: 'https://imagedelivery.net/F646Wun-eua00pA0NmkORQ/ef063980-20d8-4519-c640-631905b6f700/public',
        customerName: 'AlertaCoches',
        buttonUrl: 'https://about.alertacoches.es/servicios/publica-tus-vehiculos',
    },
];
