import { doEditUser, doEditUserPassword, doEditLoggedOutUserPassword } from '@/infrastructure/services/userService';

import store from '@/infrastructure/persistence/store/vuex';

async function edit(query) {
    const vuexstore = store.repository;
    try {
        const response = await doEditUser(query);

        if (response.status === 200) {
            response.data.user.token = response.data.token;
            vuexstore.dispatch('user/setUsersData', response.data.user);
            return true;
        }

        if (response.status >= 500) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Error inesperado al cambiar tus datos. Vuelve a intentarlo más tarde.',
            );
            return false;
        }

        vuexstore.dispatch('feedbackInterface/setErrorResponse', response.data.message);
        return false;
    } catch (error) {
        return 0;
    }
}

async function editPassword(query) {
    const vuexstore = store.repository;
    try {
        const response = await doEditUserPassword(query);

        if (response.status === 200) {
            vuexstore.dispatch('feedbackInterface/setSuccesfulResponse', 'Has cambiado tu contraseña correctamente.');
            return true;
        }

        if (response.status >= 500) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Error inesperado al cambiar tu contraseña. Vuelve a intentarlo más tarde.',
            );
            return false;
        }

        vuexstore.dispatch('feedbackInterface/setErrorResponse', response.data.message);
        return false;
    } catch (error) {
        return 0;
    }
}

async function editLoggedOutPassword(query) {
    const vuexstore = store.repository;
    try {
        const response = await doEditLoggedOutUserPassword(query);

        if (response.status === 200) {
            vuexstore.dispatch(
                'feedbackInterface/setSuccesfulResponse',
                'Has cambiado tu contraseña correctamente. Ya puedes iniciar sesión.',
            );
            return true;
        }

        if (response.status >= 500) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Error inesperado al cambiar tu contraseña. Vuelve a intentarlo más tarde.',
            );
            return false;
        }

        vuexstore.dispatch('feedbackInterface/setErrorResponse', response.data.message);
        return false;
    } catch (error) {
        return 0;
    }
}

export { edit, editPassword, editLoggedOutPassword };
