<template>
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-6 col-lg-3 anuncios">
                <h3>{{ $t('number_of_sources_label') }}</h3>
                <p class="number">
                    {{ $t('number_of_sources') }}
                    <i class="fas fa-cloud"></i>
                </p>
                <p class="description text-center">
                    {{ $t('source_list_msg') }}
                </p>
            </div>
            <hr class="d-md-none" />
            <div class="col-12 col-md-6 col-lg-3 anuncios">
                <h3>{{ $t('number_of_ads_label') }}</h3>
                <p class="number">
                    {{ $t('number_of_ads') }}
                    <i class="far fa-file-alt"></i>
                </p>
                <p class="description text-center">
                    {{ $t('update_rate_msg') }}
                </p>
            </div>
            <hr class="d-md-none" />
            <div class="col-12 col-md-6 col-lg-3 anuncios">
                <h3>{{ $t('number_of_brands_label') }}</h3>
                <p class="number">
                    {{ $t('number_of_brands') }}
                    <i class="far fa-file-alt"></i>
                </p>
                <p class="description text-center">
                    {{ $t('brand_msg') }}
                </p>
            </div>
            <hr class="d-md-none" />
            <div class="col-12 col-md-6 col-lg-3 anuncios">
                <h3>{{ $t('number_of_models_label') }}</h3>
                <p class="number">
                    {{ $t('number_of_models') }}
                    <i class="far fa-file-alt"></i>
                </p>
                <p class="description text-center">
                    {{ $t('model_msg') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
