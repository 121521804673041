import { getApiSourceBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

async function getValuesServiceBasedOnParent(filter_id = '', parent_id = '') {
    try {
        const getValuesResponse = await HttpClient.get(
            `${getApiSourceBaseUrl()}/filter/${filter_id}/?parentValueId=${parent_id}`,
        );
        if (!getValuesResponse) {
            throw Error('Invalid filter');
        }
        return getValuesResponse.data;
    } catch (err) {
        return err;
    }
}

export { getValuesServiceBasedOnParent };
