import axios from 'axios';
import store from '@/infrastructure/persistence/store/vuex';

class HttpClient {
    constructor(client) {
        this.vuexstore = store.repository;
        this.client = client;
        this.headers = {
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        };
    }
    async get(url, params, headers = this.headers) {
        if (this.vuexstore.state.user.token) {
            headers.Authorization = `Bearer ${this.vuexstore.state.user.token}`;
        }

        const response = await this.client.get(url, { params, headers }).catch((err) => {
            return err.response;
        });

        this.responseMiddleware(response);
        return response;
    }
    async post(url, body, headers = this.headers) {
        if (this.vuexstore.state.user.token) {
            headers.Authorization = `Bearer ${this.vuexstore.state.user.token}`;
        }

        const response = await this.client.post(url, body, { headers }).catch((err) => {
            return err.response;
        });

        this.responseMiddleware(response);
        return response;
    }
    async patch(url, body, headers = this.headers) {
        if (this.vuexstore.state.user.token) {
            headers.Authorization = `Bearer ${this.vuexstore.state.user.token}`;
        }

        const response = await this.client.patch(url, body, { headers }).catch((err) => {
            return err.response;
        });

        this.responseMiddleware(response);
        return response;
    }
    async put(url, body, headers = this.headers) {
        if (this.vuexstore.state.user.token) {
            headers.Authorization = `Bearer ${this.vuexstore.state.user.token}`;
        }

        const response = await this.client.put(url, body, { headers }).catch((err) => {
            return err.response;
        });

        this.responseMiddleware(response);
        return response.data;
    }
    async delete(url, headers = this.headers) {
        if (this.vuexstore.state.user.token) {
            headers.Authorization = `Bearer ${this.vuexstore.state.user.token}`;
        }

        const response = await this.client.delete(url, { headers }).catch((err) => {
            return err.response;
        });

        this.responseMiddleware(response);
        return response;
    }

    responseMiddleware(response) {
        if (response.status === 401) {
            this.vuexstore.dispatch('user/logOut');
            this.$router.push({ name: 'home' });
        }
    }
}

export default new HttpClient(axios);
