<template>
    <div class="col-12 col-xl-6 p-1 mb-3 simple-an-card">
        <v-btn
            icon="mdi mdi-delete delete-button"
            variant="text"
            density="compact"
            :class="card.status ? '' : 'not-available'"
            @click="removeFavourite(card)"
        ></v-btn>
        <div class="media-section">
            <div class="image-container">
                <template v-if="!card.images[0]">
                    <img src="@/assets/icons/no-picture.webp" alt="Icono de coche: el anuncio no tiene imagenes." />
                </template>
                <template v-else>
                    <img
                        loading="lazy"
                        :src="getCorrectSizedImage(card.images[0], card.sourceId)"
                        data-image-id="0"
                        :alt="card.title"
                        :title="`${card.title} de segunda mano`"
                    />
                </template>
            </div>
            <div class="map-container">
                <iframe
                    title="Mapa con las localizaciones de tus anuncios favoritos"
                    class="map-iframe"
                    width="100%"
                    height="100%"
                    :src="`https://www.openstreetmap.org/export/embed.html?bbox=${vertical.lng}%2C${vertical.lat}%2C${horizontal.lng}%2C${horizontal.lat}&amp;layer=mapnik&amp;attributionControl=0&amp;marker=${marker.lat}%2C${marker.lng}`"
                ></iframe>
            </div>
        </div>
        <div class="caption-container pt-2 pb-2" v-if="card.status">
            <p class="an-title">{{ card.title }}</p>
            <p class="an-price">
                {{ $n(card.price, 'currency', { currency: card.currency }) }}
            </p>
            <router-link
                class="an-link btn btn-simple-card"
                rel="follow"
                :to="{ name: 'singleAd', params: { slugUrl: this.parseSlugUrl(card.slugUrl) } }"
            >
                <span>Ver</span>
            </router-link>
        </div>
        <div class="caption-container pt-2 pb-2" v-else>
            <p class="not-available-title">No disponible</p>
        </div>
    </div>
</template>
<script>
import { toggleFavouriteStatus } from '@/application/favourite/toggleFavourite';

export default {
    props: ['card', 'isFavourite'],
    data() {
        return {
            showImg: true,
            favouriteStatus: this.isFavourite,
            marker: {
                lat: 0,
                lng: 0,
            },
            vertical: {
                lat: null,
                lng: null,
            },
            horizontal: {
                lat: null,
                lng: null,
            },
        };
    },
    mounted() {
        this.setMarker(this.card);
    },
    methods: {
        imageUrlAlt(event) {
            let picIdx = event.target.getAttribute('data-image-id');
            if (picIdx < 10) {
                picIdx++;
                event.target.setAttribute('data-image-id', picIdx);
                event.target.src = this.card.images[picIdx];
            } else {
                event.target.src = '/src/assets/icons/car.png';
                event.target.alt = 'Icono de coche: el anuncio no tiene imagenes.';
            }
        },
        setMarker(ad) {
            this.marker.lat = parseFloat(ad.location.longitude);
            this.marker.lng = parseFloat(ad.location.latitude);
            this.setMapEdges();
        },
        setMapEdges() {
            this.vertical.lat = this.marker.lat + 0.0305;
            this.horizontal.lat = this.marker.lat - 0.0305;
            this.vertical.lng = this.marker.lng;
            this.horizontal.lng = this.marker.lng;
        },
        getCorrectSizedImage(imageUrl, source) {
            switch (source) {
                case 2:
                    return imageUrl.replace('pictureSize=W800', 'pictureSize=W100');
                case 8:
                    if (imageUrl === 'https://images.coches.com/_vo_/default.pngp=cc_vo_medium') {
                        return 'https://images.coches.com/_vo_/default.png?p=cc_vo_medium';
                    }
                    return imageUrl + '?p=cc_vo_medium';
                default:
                    return imageUrl;
            }
        },
        parseSlugUrl(slugUrl) {
            return slugUrl.toLowerCase().replaceAll('.', '').replaceAll('/', '');
        },
        removeFavourite(ad) {
            toggleFavouriteStatus(ad.id);
            this.$store.dispatch('favourites/removeFavourite', ad);
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.not-available {
    text-shadow: $white 0 0 10px !important;
    .image-container {
        border: 1px solid $tertiary !important;
        opacity: 0.5 !important;
        pointer-events: none;
    }
}
.simple-an-card {
    border-radius: $border-radius-deep;
    &:hover {
        cursor: pointer;
    }
    position: relative;
    border-radius: $border-radius;
    min-height: 200px;
    text-decoration: none !important;
    background-color: transparent;
    padding: 5px;
    button {
        position: absolute;
        z-index: 100;
        height: 35px !important;
        width: 35px !important;
        top: 10px;
        right: 10px;
        padding: 0;
        font-size: 0.9rem !important;
        opacity: 100%;
        cursor: pointer;
        color: $secondary;
        transition: all 0.7s ease;
        background-color: $white;
    }
    a {
        text-decoration: none;
    }
    .media-section {
        width: 100%;
        height: 230px;
    }
    .image-container {
        position: relative;
        float: left;
        width: 55%;
        height: 100%;
        background-color: $white;
        overflow: hidden;
        border-top-left-radius: $border-radius-deep;
        border-bottom-left-radius: $border-radius-deep;
        aspect-ratio: 1 / 1;
        transition: all 0.7s ease;
        img {
            position: relative;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .map-container {
        position: relative;
        float: left;
        width: 45%;
        height: 100%;
        background-color: $white;
        overflow: hidden;
        border-top-right-radius: $border-radius-deep;
        border-bottom-right-radius: $border-radius-deep;
        transition: all 0.7s ease;
        .map-iframe {
            float: right;
            height: calc(100% + 20%);
            pointer-events: none;
        }
    }
    .caption-container {
        position: absolute;
        background-color: rgb(255, 255, 255, 0.9);
        margin-top: -70px;
        float: left;
        overflow: hidden;
        height: 70px;
        width: 100%;
        text-align: left;
        padding: 15px !important;
        .an-title {
            width: 55%;
            font-size: 0.8em !important;
            color: $secondary;
            margin: 0px;
            margin-bottom: 2px;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .not-available-title {
            width: 100%;
            font-size: 0.8em !important;
            color: $tertiary;
            margin: 0px;
            margin-bottom: 2px;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .an-price {
            position: relative;
            float: left;
            width: 50%;
            margin-bottom: 2px;
            color: $tertiary;
            font-size: 1.1em !important;
            font-weight: bold;
            img {
                position: relative;
                float: right;
                bottom: 0;
                right: 0;
                height: 22px;
                width: auto;
                border-radius: 4px;
                margin: auto 0;
            }
        }
        .an-link {
            position: relative;
            padding: 16px 50px !important;
            float: left;
            margin-bottom: 2px;
            color: $white;
            font-size: 0.8em !important;
            font-weight: bold;
        }
        .btn-simple-card {
            float: right;
            bottom: 30px;
            right: 5;
            background-color: $secondary;
            border-radius: $border-radius-deep !important;
        }
        .an-paragraph {
            width: 100%;
            margin-bottom: 2px;
            color: $tertiary;
            font-size: 0.81em !important;
            font-weight: bold;
        }
        .an-location {
            width: 100%;
            height: 20px;
            font-size: 0.8em !important;
            color: $secondary;
            vertical-align: middle;
            padding: 2px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            img {
                width: auto;
                height: 16px;
                margin-top: -4px;
            }
        }
    }
    //xs
    @media (max-width: $breakpoint-xs) {
    }
    //sm
    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    }
    //md
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    }
    //lg
    @media (min-width: $breakpoint-md) {
    }
}
</style>
