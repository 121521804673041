import { doSendVerificationEmail } from '@/infrastructure/services/authService';

import store from '@/infrastructure/persistence/store/vuex';

async function sendVerificationEmail(query) {
    const vuexstore = store.repository;
    try {
        const response = await doSendVerificationEmail(query);

        if (response.status === 200) {
            vuexstore.dispatch(
                'feedbackInterface/setSuccesfulResponse',
                'Te hemos enviado un email para que puedas verificar tu cuenta. Revisa tu bandeja de entrada.',
            );

            return true;
        }

        if (response.status >= 500) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Error inesperado al cerrar sesión. Vuelve a intentarlo más tarde.',
            );
            return false;
        }

        vuexstore.dispatch('feedbackInterface/setErrorResponse', response.data.message);
        return false;
    } catch (error) {
        return 0;
    }
}

export { sendVerificationEmail };
