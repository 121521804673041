import { track } from '@/infrastructure/services/tracking';
import { isProdEnvironment } from '../../infrastructure/config';

async function trackEvent(event, data) {
    if (!isProdEnvironment) {
        // Track events only in production
        return false;
    }

    if (!event) {
        return false;
    }

    const eventData = await getEventData(event, data);

    try {
        gtag('event', event, {
            customer: data.customer.slug,
        });
    } catch (error) {
        return false;
    }

    if (!data) {
        return false;
    }
    try {
        await track(event, eventData);
    } catch (error) {
        return false;
    }
}

async function getEventData(event, data) {
    let eventData = {};

    if (isCustomerEvent(event)) {
        if (typeof data.customer === 'undefined') {
            return false;
        }

        eventData = {
            ...eventData,
            customer_id: data.customer.customerId,
        };
    }

    if (typeof data.extra !== 'undefined') {
        eventData = {
            ...eventData,
            ...data.extra,
        };
    }

    return eventData;
}

function isCustomerEvent(event) {
    return event.startsWith('customer_');
}

export { trackEvent };
