import Login from '../../views/auth/Login.vue';
import Register from '../../views/auth/Register.vue';
import ForgotPassword from '../../views/auth/ForgotPassword.vue';

const loginRoutes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/login/verificado',
        name: 'login.verified',
        component: Login,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
    },
    {
        path: '/forgot-password',
        name: 'forgot.password',
        component: ForgotPassword,
    },
];

export default loginRoutes;
