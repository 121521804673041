<template>
    <div class="page" ref="AdNotFound">
        <SimpleNavBar />

        <div class="ad-not-found mt-7 p-4">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-4 m-auto">
                        <p class="unimportant-title">¡Lo sentimos, pero el anuncio ya no está!</p>
                        <img
                            src="@/assets/images/undraw_feeling_blue_-4-b7q.svg"
                            alt="No hay resultados para esta búsqueda"
                        />
                    </div>
                    <div class="col-12 col-md-6 mb-4 align-left">
                        <p>
                            Parece que este vehículo ya no está disponible... pero no te desanimes: tenemos
                            <b>la mayor oferta de coches de segunda mano de España</b>
                            , y todos están esperando por ti.
                        </p>
                        <p>Empieza a buscar en los principales portales de compraventa, desde un único buscador.</p>
                    </div>
                </div>

                <div id="cta_search" class="row d-flex justify-content-center text-center cta-search">
                    <router-link :to="{ name: 'home' }">
                        <v-btn size="x-large" density="default" prepend-icon="mdi-magnify">
                            Buscar mi próximo coche
                        </v-btn>
                    </router-link>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import Footer from '@/components/structural/Footer.vue';

export default {
    components: {
        SimpleNavBar,
        Footer,
    },
    data() {
        return {};
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.ad-not-found {
    min-height: calc(100vh - 500px);
    margin: auto;
    .unimportant-title {
        font-size: 22px;
    }
    img {
        width: 300px;
        height: auto;
    }
}
.cta-search {
    p {
        font-size: 15px;
        margin: auto;
    }
    button {
        background-color: $secondary;
        color: $white;
        font-size: 13px;
        font-weight: bold;
        border-radius: $border-radius-deep;
        padding: 10px 16px;
    }
}
</style>
