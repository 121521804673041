<template>
    <div class="col-6 col-md-4 col-xl-3 mb-3">
        <div class="col-12 skeleton-ad-card">
            <a target="_blank" class="col-12" style="text-decoration: none">
                <div class="image-container">
                    <div class="img"></div>
                </div>
                <div class="caption-container p-2">
                    <p class="an-title">-</p>
                    <p class="an-price">-</p>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    methods: {},
    setup() {},
};
</script>
<style lang="scss">
@import '@/styles/main.scss';
.skeleton-ad-card {
    position: relative;
    border-radius: $border-radius;
    min-height: 200px;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-animation: ease flickerAnimation 1s infinite;
    -moz-animation: ease flickerAnimation 1s infinite;
    -o-animation: ease flickerAnimation 1s infinite;
    animation: ease flickerAnimation 1s infinite;
    .image-container {
        background-color: $gray-10;
        overflow: hidden;
        border-radius: $border-radius;
        width: 100%;
        aspect-ratio: 1 / 1;
        transition: all 0.7s ease;
        filter: grayscale(10%);
    }
    .caption-container {
        overflow: hidden;
        min-height: 50px;
        text-align: left;
        background-color: transparent;
        .an-title {
            height: 13px;
            background-color: $gray-10;
            width: 100%;
            font-size: 0.9em !important;
            color: $gray-10;
            margin: 0px;
            border-radius: $border-radius;
        }
        .an-price {
            height: 16px;
            background-color: $gray-10;
            width: 25%;
            margin-bottom: 0px;
            margin-top: 4px;
            color: $gray-10;
            font-size: 1.1em !important;
            font-weight: bold;
            border-radius: $border-radius;
        }
    }
    //xs
    @media (max-width: $breakpoint-xs) {
    }
    //sm
    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    }
    //md
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    }
    //lg
    @media (min-width: $breakpoint-md) {
    }
}
.skeleton-ad-card {
    .seller-box {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 4px;
        background-color: $white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 5px;
        .seller-icon {
            width: 20px;
            height: 20px;
            border-radius: 0px !important;
            background-color: $gray-10;
            content: '';
        }
    }
    .back-container {
        border-radius: $border-radius;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
    }
    .front-container {
        position: relative;
        border-radius: $border-radius;
        float: left;
        z-index: 15;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: $white;
        overflow: hidden;
        @include aux-box-shadow;
        .car-info {
            margin: 10px;
            margin-left: 0px;
            height: 205px;
            border-bottom-right-radius: 9em;
            overflow: hidden;
            border-left: 2px dashed $gray-light;
            font-size: 0.9em;
            .ad-title {
                width: 100%;
                background-color: $gray-10;
                color: $gray-10 !important;
            }
            .ad-price {
                position: absolute;
                background-color: $gray-10;
                bottom: 0;
                width: 70px;
                margin-bottom: 8px;
                z-index: 15;
                color: $gray-10 !important;
            }
            .info-bubble {
                background-color: $gray-10;
                width: 50px;
                height: 18px;
                color: $gray-10 !important;
                margin-right: 5px;
                color: $gray;
            }
        }
        //xs
        @media (max-width: $breakpoint-xs) {
            .car-info {
                max-width: calc(100% - 130px);
            }
        }
        //sm
        @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
            .car-info {
                max-width: calc(100% - 150px);
            }
        }
        //md
        @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
            .car-info {
                max-width: calc(100% - 160px);
            }
        }
        //lg
        @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
            .car-info {
                max-width: calc(100% - 150px);
            }
        }
        //xl
        @media (min-width: $breakpoint-xl) {
            .car-info {
                max-width: calc(100% - 120px);
            }
        }
        .img {
            position: relative;
            float: left;
            top: 0;
            left: 0;
            z-index: 1;
            margin: 10px;
            object-fit: cover;
            border-radius: $border-radius;
            height: 145px;
            aspect-ratio: 1/1;
            background-color: $gray-10;
        }
        //xs
        @media (max-width: $breakpoint-xs) {
            img {
                width: 100px;
            }
        }
        //sm
        @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
            img {
                width: 120px;
            }
        }
        //md
        @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
            img {
                width: 130px;
            }
        }
        //lg
        @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
            img {
                width: 120px;
            }
        }
        //xl
        @media (min-width: $breakpoint-xl) {
            img {
                width: 90px;
            }
        }
    }
    //xs
    @media (max-width: $breakpoint-xs) {
        .front-container {
            border-bottom-right-radius: 9em;
        }
    }
    //sm
    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        .front-container {
            border-bottom-right-radius: 9em;
        }
    }
    //md
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        .front-container {
            border-bottom-right-radius: 14em;
        }
    }
    //lg
    @media (min-width: $breakpoint-md) {
        .front-container {
            border-bottom-right-radius: 9em;
        }
    }
}
</style>
