<template>
    <v-snackbar v-if="errorResponse" :timeout="5000" v-model="errorResponse" color="#fabcbc">
        <div class="error-snackbar">
            <div class="subtitle pb-2">Ups!</div>

            <p>{{ this.errorResponse }}</p>
        </div>

        <template v-slot:actions>
            <v-btn color="#ad6262" variant="text" @click="errorResponse = false">Cerrar</v-btn>
        </template>
    </v-snackbar>

    <v-snackbar v-if="succesfulResponse" :timeout="5000" v-model="succesfulResponse" color="#B7E8D6">
        <div class="success-snackbar">
            <div class="subtitle pb-2">Genial!</div>

            <p>{{ this.succesfulResponse }}</p>
        </div>

        <template v-slot:actions>
            <v-btn color="#14746f" variant="text" @click="succesfulResponse = false">Cerrar</v-btn>
        </template>
    </v-snackbar>
</template>
<script>
export default {
    data() {
        return {
            succesfulResponse: null,
            errorResponse: null,
        };
    },
    mounted() {},
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'feedbackInterface/setSuccesfulResponse') {
                this.succesfulResponse = state.feedbackInterface.succesfulResponse;
            }

            if (mutation.type === 'feedbackInterface/setErrorResponse') {
                this.errorResponse = state.feedbackInterface.errorResponse;
            }
        });
    },
};
</script>
<style lang="scss">
.success-snackbar {
    color: #14746f !important;
}
.error-snackbar {
    color: #ad6262 !important;
}
.subtitle {
    font-weight: bold;
    font-size: 1rem !important;
}
</style>
