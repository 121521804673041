import { getApiParserBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

async function doEditUser(query) {
    try {
        const logoutResponse = await HttpClient.patch(`${getApiParserBaseUrl()}/user/edit`, query);
        return logoutResponse;
    } catch (err) {
        throw err;
    }
}

async function doEditUserPassword(query) {
    try {
        const logoutResponse = await HttpClient.patch(`${getApiParserBaseUrl()}/user/edit/password`, query);
        return logoutResponse;
    } catch (err) {
        throw err;
    }
}

async function doEditLoggedOutUserPassword(query) {
    try {
        const logoutResponse = await HttpClient.post(`${getApiParserBaseUrl()}/reset/password/confirm`, query);
        return logoutResponse;
    } catch (err) {
        throw err;
    }
}

export { doEditUser, doEditUserPassword, doEditLoggedOutUserPassword };
