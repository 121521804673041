export default {
    namespaced: true,
    state: () => {
        return {
            items: [],
            loading: false,
            errorFlag: false,
        };
    },
    mutations: {
        setFavourites(state, value) {
            state.items = value;
        },
        setErrorFlag(state, value) {
            state.errorFlag = value;
        },
        clearFavourites(state) {
            state.items = [];
        },
        setLoading(state, value) {
            state.loading = value;
        },
    },
    getters: {
        getFavourites(state) {
            return state.items;
        },
        isLoading(state) {
            return state.loading;
        },
        hasError(state) {
            return state.errorFlag;
        },
        hasNoResults(state) {
            return state.items.length === 0 && !state.loading;
        },
    },
    actions: {
        setFavourites({ commit }, payload) {
            commit('setFavourites', payload);
        },
        setErrorFlag({ commit }, payload) {
            commit('setErrorFlag', payload);
        },
        clear({ commit }) {
            commit('clearFavourites');
        },
        setLoading({ commit }, payload) {
            commit('setLoading', payload);
        },
        removeFavourite({ commit, state }, payload) {
            const newFavouritesList = state.items.filter((item) => item.id !== payload.id);
            commit('setFavourites', newFavouritesList);
        },
    },
};
