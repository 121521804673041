import { getApiParserBaseUrl } from '@/infrastructure/config';
import { useRouter } from 'vue-router';
import HttpClient from './httpClient';

const router = useRouter();

async function searchAnuncioService(query, isAuthenticated = false) {
    try {
        const urlParsed = new URLSearchParams();

        let switchPerPage = false;
        for (const key in query) {
            if (key === 'perpage') {
                urlParsed.append(key, query.perpage);
                switchPerPage = true;
            } else {
                urlParsed.append(key, query[key]);
            }
        }

        if (!switchPerPage) {
            urlParsed.append('perpage', 24);
        }

        let searchAdsResponse;
        if (isAuthenticated) {
            searchAdsResponse = await HttpClient.get(`${getApiParserBaseUrl()}/user/search?${urlParsed.toString()}`);
        } else {
            searchAdsResponse = await HttpClient.get(`${getApiParserBaseUrl()}/search?${urlParsed.toString()}`);
        }

        if (!searchAdsResponse) {
            throw Error('Invalid search');
        }

        return searchAdsResponse.data;
    } catch (err) {
        throw err;
    }
}
async function countAnuncioService(query) {
    try {
        const urlParsed = new URLSearchParams();

        for (const key in query) {
            urlParsed.append(key, query[key]);
        }

        const countAdsResponse = await HttpClient.get(`${getApiParserBaseUrl()}/search/count?${urlParsed.toString()}`);
        if (!countAdsResponse) {
            throw Error('Invalid search');
        }
        return countAdsResponse.data;
    } catch (err) {
        throw err;
    }
}

async function searchLastFoundService(query = null) {
    try {
        if (query === null) {
            var searchAdsResponse = await HttpClient.get(`${getApiParserBaseUrl()}/search/last?page=1&perpage=30`);
        } else {
            const urlParsed = new URLSearchParams();

            var switchPerPage = false;
            for (const key in query) {
                if (key === 'perpage') {
                    urlParsed.append(key, query.perpage);
                    switchPerPage = true;
                } else {
                    urlParsed.append(key, query[key]);
                }
            }

            if (!switchPerPage) {
                urlParsed.append('perpage', 24);
            }

            var searchAdsResponse = await HttpClient.get(
                `${getApiParserBaseUrl()}/search/last?${urlParsed.toString()}`,
            );
        }
        return searchAdsResponse.data;
    } catch (err) {
        throw err;
    }
}

async function searchSingleAdService(id) {
    try {
        const searchAdResponse = await HttpClient.get(`${getApiParserBaseUrl()}/element/${id}`);

        if (!searchAdResponse) {
            throw Error('Invalid request');
        }

        if (searchAdResponse.status === 422) {
            window.location.href = '/coches-segunda-mano/anuncio/no-disponible';
        }

        return searchAdResponse.data;
    } catch (err) {
        throw err;
    }
}

export { searchAnuncioService, countAnuncioService, searchLastFoundService, searchSingleAdService };
