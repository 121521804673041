import Profile from '../../views/user/Profile.vue';
import History from '../../views/user/History.vue';
import Favourites from '../../views/user/Favourites.vue';
import ResetPassword from '../../views/auth/ResetPassword.vue';

const userRoutes = [
    {
        path: '/reset/password',
        name: 'reset.password',
        component: ResetPassword,
    },
    {
        path: '/favourites',
        name: 'favourites',
        component: Favourites,
        meta: {
            buttonPositionIndex: 1,
        },
    },
    {
        path: '/history',
        name: 'history',
        component: History,
        meta: {
            buttonPositionIndex: 2,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            buttonPositionIndex: 3,
        },
    },
];

export default userRoutes;
