<template v-if="this.ad && this.providerUrl">
    <div class="col-12 col-md-6 col-xl-6 p-1 mt-4" id="calculadora_revision">
        <div class="embedded-service">
            <h3>Revísalo con un perito especializado</h3>
            <p>
                Solicita una revisión detallada del estado real del vehículo antes de comprarlo.
                <b>Compra con seguridad.</b>
            </p>
            <div class="action">
                <a target="_blank" rel="nofollow" :href="this.providerUrl" @click="registerCollabAdClick()">
                    <div class="link">
                        <span>Solicitar presupuesto de la revisión</span>
                    </div>
                </a>
            </div>
            <img
                loading="lazy"
                src="https://revisamoselcoche.com/admin/images/settings/logo//230x74/405KG2rY2L.webp"
                alt="Calcula el coste de la revisión de tu coche"
            />
        </div>
    </div>
</template>
<script>
import { trackEvent } from '@/application/tracking/track';
export default {
    props: ['ad', 'makeList', 'modelList'],
    data() {
        return {
            providerUrl: null,
            make: null,
            model: null,
            province: null,
        };
    },
    methods: {
        async registerCollabAdClick() {
            await trackEvent('customer_ad_click', {
                customer: {
                    slug: 'revisamos-el-coche',
                    customerId: 3,
                },
                extra: {
                    type: 'calculator',
                    make: this.make,
                    model: this.model,
                    province: this.ad.location.province,
                },
            });
        },
        async getPrividerUrl() {
            this.make = await this.getShortDesc(this.ad.make, this.makeList);
            this.model = await this.getShortDesc(this.ad.model, this.modelList);
            let cp = this.ad.location.zip_code;
            let anuncio = this.ad.id;

            this.providerUrl =
                'https://revisamoselcoche.com/inspection/request?utm_campaign=calculadora&utm_source=alertacoches&utm_medium=producto';
            if (this.make) {
                this.providerUrl += `&marca=${this.make}`;
            }

            if (this.model) {
                this.providerUrl += `&modelo=${this.model}`;
            }

            if (cp) {
                this.providerUrl += `&cp=${cp}`;
            }

            if (anuncio) {
                this.providerUrl += `&anuncioAlertaCoches=${anuncio}`;
            }
        },
        async getShortDesc(id, haystack) {
            if (!id) {
                return null;
            }
            if (!haystack) {
                return null;
            }

            let obj = haystack.find((o) => o.value_id === id);

            if (!obj) {
                return null;
            }

            return obj.short_desc;
        },
    },
    setup() {},
    async beforeMount() {
        await this.getPrividerUrl();
    },
    async mounted() {
        await trackEvent('customer_ad_display', {
            customer: {
                slug: 'revisamos-el-coche',
                customerId: 3,
            },
            extra: {
                type: 'calculator',
                make: this.make,
                model: this.model,
                province: this.ad.location.province,
            },
        });
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.embedded-service {
    height: 100%;
    margin: auto;
    min-height: 140px;
    background-color: $white !important;
    border-radius: $border-radius-deep;
    font-weight: 800;
    padding: 15px;
    border: 2px dashed $gray-15;
    text-align: center;
    h3 {
        font-size: 0.9rem;
        margin-bottom: 0px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    p {
        font-size: 0.75rem;
        margin: 15px 0px;
    }
    div {
        position: relative;
        margin: 10px auto;
    }
    .action {
        margin: 15px auto;
        width: 90%;
        .link {
            width: 100%;
            padding: 10px 0px;
            background-color: #31a3b1;
            border-radius: $border-radius-deep;
        }
        span {
            font-family: $secondary-font;
            text-transform: uppercase;
            color: $white;
            font-size: 0.7rem;
            height: 60px;
            width: 100%;
            margin-bottom: 0px;
            font-weight: 800;
        }
    }
    img {
        width: 120px;
        height: auto;
        margin: 10px auto;
    }
    .price-tag {
        position: relative;
        float: right;
        top: 0px;
        right: 0px;
        background-color: $gray-15;
        border-radius: $border-radius-deep;
        padding: 5px 10px;
        font-size: 1rem;
        font-weight: bold;
        color: $secondary;
    }
}
</style>
