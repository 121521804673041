import bannerAds from './banner';
import nativeAds from './native';
import nativeBannerAds from './native_banner';
import popUpAds from './pop-up';

const AD_CAMPAIGN = {
    banner: bannerAds,
    banner_seo: bannerAds,
    native: nativeAds,
    popup: popUpAds,
    native_banner: nativeBannerAds,
};

async function getCollabResource(collabData, isMobile) {
    if (collabData.type === 'popup') {
        if (isMobile) {
            var selectedAd = await getRandomAdFromList(AD_CAMPAIGN[collabData.type].mobile);
        } else {
            var selectedAd = await getRandomAdFromList(AD_CAMPAIGN[collabData.type].desktop);
        }
    } else {
        var selectedAd = await getRandomAdFromList(AD_CAMPAIGN[collabData.type]);
    }

    selectedAd.type = collabData.type;
    selectedAd.make = collabData.make;
    selectedAd.model = collabData.model;

    return selectedAd;
}

async function getFixedCollabResource(collabData, index) {
    let selectedAd = await getFixedAdFromList(AD_CAMPAIGN[collabData.type], index);
    selectedAd.type = collabData.type;
    selectedAd.make = collabData.make;
    selectedAd.model = collabData.model;

    return selectedAd;
}

async function getRandomAdFromList(list) {
    return list[Math.floor(Math.random() * list.length)];
}

async function getFixedAdFromList(list, idx) {
    return list[idx];
}

export { getCollabResource, getFixedCollabResource };
