import { getApiSourceBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

async function getValuesService(filter_id = '', order_by = null) {
    try {
        const params = {
            orderBy: order_by,
        };
        const getValuesResponse = await HttpClient.get(`${getApiSourceBaseUrl()}/filter/${filter_id}`, params);
        if (!getValuesResponse) {
            throw Error('Invalid filter');
        }
        return getValuesResponse.data;
    } catch (err) {
        return err;
    }
}

async function getValuesBySlugService(slug = '') {
    try {
        const getValuesResponse = await HttpClient.get(`${getApiSourceBaseUrl()}/value/${slug}`);
        if (!getValuesResponse) {
            throw Error('Invalid value');
        }
        return getValuesResponse.data;
    } catch (err) {
        return err;
    }
}

async function getValuesBySlugBasedOnParentService(parentSlug = '', slug = '') {
    try {
        const getValuesResponse = await HttpClient.get(`${getApiSourceBaseUrl()}/value/${parentSlug}/${slug}`);
        if (!getValuesResponse) {
            throw Error('Invalid value or parent');
        }
        return getValuesResponse.data;
    } catch (err) {
        return err;
    }
}

export { getValuesService, getValuesBySlugService, getValuesBySlugBasedOnParentService };
