export default {
    mobile: [
        {
            designId: 'picturePopUp',
            contentId: 'menos-72h',
            pictureSrc: '/NeedCarHelp/popup/Bann_popup_mobile_382x400_menos_72h.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=mobile&utm_campaign=menos_72h',
        },
        {
            designId: 'picturePopUp',
            contentId: 'no-compres',
            pictureSrc: '/NeedCarHelp/popup/Bann_popup_mobile_382x400_no_compres.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=mobile&utm_campaign=no_compres',
        },
        {
            designId: 'picturePopUp',
            contentId: 'que-no-te-lien',
            pictureSrc: '/NeedCarHelp/popup/Bann_popup_mobile_382x400_que_no_te_lien.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=mobile&utm_campaign=que_no',
        },
        {
            designId: 'picturePopUp',
            contentId: 'suenos-vs-pesadilla',
            pictureSrc: '/NeedCarHelp/popup/Bann_popup_mobile_382x400_sueño_vs_pesadilla.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=mobile&utm_campaign=pesadilla',
        },
        {
            designId: 'picturePopUp',
            contentId: 'que-no-te-lien',
            pictureSrc: '/NeedCarHelp/popup/Bann_popup_mobile_382x400_ahorra_3000.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=mobile&utm_campaign=ahorra_3',
        },
        {
            designId: 'picturePopUp',
            contentId: 'ahorra-tiempo',
            pictureSrc: '/NeedCarHelp/popup/Bann_popup_mobile_382x400_ahorra_tiempo.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=mobile&utm_campaign=tiempo',
        },
    ],
    desktop: [
        {
            designId: 'picturePopUp',
            contentId: 'consejos-amigo',
            pictureSrc: '/NeedCarHelp/popup/Popup_compu_800x400_consejos_amigo.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=popup&utm_campaign=consejos',
        },
        {
            designId: 'picturePopUp',
            contentId: 'suenos-vs-pesadilla',
            pictureSrc: '/NeedCarHelp/popup/Popup_compu_800x400_sueño_vs_pesadilla.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=popup&utm_campaign=pesadilla',
        },
        {
            designId: 'picturePopUp',
            contentId: 'te-has-decidido',
            pictureSrc: '/NeedCarHelp/popup/Popup_compu_800x400_te_has_decidido.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=popup&utm_campaign=decidido',
        },
        {
            designId: 'picturePopUp',
            contentId: 'tranquilidad-prioridad',
            pictureSrc: '/NeedCarHelp/popup/Popup_compu_800x400_tranquilid_priorid.jpg',
            customerName: 'NeedCarHelp',
            buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=popup&utm_campaign=tranquilid',
        },
    ],
};
