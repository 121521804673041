import { getSearchHistory } from '@/infrastructure/services/searchHandler';
async function getListOfSearches() {
    try {
        const searches = await getSearchHistory();
        return searches;
    } catch (error) {
        throw Error(error.message);
    }
}

export { getListOfSearches };
