<template>
    <div class="page">
        <HeadingSection
            ref="HeadingSection"
            :seoBrand="this.seoBrand"
            :seoModel="null"
            :seoProvince="null"
            :isSeoPage="true"
            v-if="this.seoBrand !== null"
        />
        <HeadingSectionSkeleton v-else />
        <div class="container text-left" id="last_added" v-if="this.seoBrand !== null">
            <div class="row d-flex justify-content-left p-1" id="results_area">
                <h2>Últimos {{ this.seoBrand.short_desc }} anunciados en España</h2>
                <template v-if="(typeof ads.items == 'undefined' || ads.items.length == 0) && ads.loading">
                    <SkeletonAdCard v-for="i in 20" :key="i" />
                </template>
                <template v-else>
                    <AdCard v-for="ad in ads.items" :key="ad.id" :card="ad" />
                    <template v-if="!ads.initialSearch && ads.loading">
                        <div class="m-auto col-8 col-md-6 col-xl-1 mb-5">
                            <LoadingSpinner />
                        </div>
                    </template>
                </template>
            </div>
        </div>
        <div class="container text-left" id="last_added" v-else>
            <div class="row d-flex justify-content-left p-1" id="results_area">
                <h2 class="h2-skeleton"></h2>
                <SkeletonAdCard v-for="i in 20" :key="i" />
            </div>
        </div>
        <div
            class="container text-left"
            id="text_area_container_1"
            v-if="this.seoBrand !== null && this.seoBrand.seo_heading_1 !== null"
        >
            <div class="row d-flex justify-content-left p-1" id="text_area_1">
                <h2>{{ this.seoBrand.seo_heading_1 }}</h2>
                <span v-html="this.seoBrand.seo_text_1"></span>
            </div>
        </div>
        <div
            class="container text-left"
            id="text_area_container_2"
            v-if="this.seoBrand !== null && this.seoBrand.seo_heading_2 !== null"
        >
            <div class="row d-flex justify-content-left p-1" id="text_area_2">
                <h2>{{ this.seoBrand.seo_heading_2 }}</h2>
                <span v-html="this.seoBrand.seo_text_2"></span>
            </div>
        </div>
        <div
            class="container text-left"
            id="text_area_container_3"
            v-if="this.seoBrand !== null && this.seoBrand.seo_heading_3 !== null"
        >
            <div class="row d-flex justify-content-left p-1" id="text_area_3">
                <h2>{{ this.seoBrand.seo_heading_3 }}</h2>
                <span v-html="this.seoBrand.seo_text_3"></span>
            </div>
        </div>

        <template v-if="this.collabData !== null">
            <div class="container text-left">
                <div class="row d-flex justify-content-left p-1 mb-4" id="check_before_buy">
                    <h2>¿Vas a comprar un coche de segunda mano sin revisarlo?</h2>
                    <p>
                        Es crucial verificar el estado del coche de segunda mano que se planea comprar antes de hacer el
                        pago. La apariencia exterior y la palabra del vendedor no son suficientes para garantizar el
                        buen estado del coche. Es posible que haya sufrido daños mecánicos no evidentes a simple vista,
                        haya estado involucrado en accidentes o haya sido maltratado por el propietario anterior. No
                        verificar su estado puede resultar en la compra de un coche o furgoneta que cause más problemas
                        de lo que vale.
                    </p>
                    <p>
                        Es recomendable contratar los servicios de un experto en automóviles o mecánico capacitado para
                        realizar una revisión detallada. De esta manera, se pueden detectar y reparar cualquier problema
                        previo a la compra y negociar un precio adecuado.
                    </p>
                    <p>
                        En
                        <b><a href="https://alertacoches.es">AlertaCoches.es</a></b>
                        confiamos en
                        <b>
                            <a
                                href="https://needcarhelp.es/?utm_source=alertacoches&utm_medium=text&utm_campaign=trafico"
                            >
                                NeedCarHelp
                            </a>
                        </b>
                        y su excelente servicio para la revisión de cualquier vehículo: turismo, SUV, furgoneta, 4x4 o
                        deportivo.
                    </p>
                    <CollabBanner :collabData="collabData" />
                </div>
            </div>
        </template>
        <div
            class="container-fluid pt-4 pb-4 mb-4 other-model"
            v-if="this.seoBrand !== null && this.seoBrand.short_desc !== null"
        >
            <div class="container text-left">
                <div class="row d-flex justify-content-left p-1" id="other_models_links">
                    <h2>Diferentes modelos de {{ this.seoBrand.short_desc }}</h2>
                    <template v-if="this.models">
                        <div class="col-12 col-lg-6 col-xxl-4 pb-2" v-for="(model, index) in models" :key="index">
                            <a :href="`/coches-segunda-mano/${this.seoBrand.slug}/${model.slug}`" rel="dofollow">
                                <b>{{ this.seoBrand.short_desc }} {{ model.short_desc }}</b>
                                de segunda mano
                                <img
                                    loading="lazy"
                                    class="icon"
                                    src="@/assets/icons/right-arrow.svg"
                                    alt="Icono de flecha hacia adelante"
                                />
                            </a>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-4 pb-4 mb-4 other-model-skeleton" v-else>
            <div class="container text-left">
                <div class="row d-flex justify-content-left p-1" id="other_models_links">
                    <h2>-</h2>
                    <div class="col-12 col-lg-6 col-xxl-4 pb-2" v-for="i in 12" :key="i">
                        <a href="#" rel="dofollow">
                            <span>-</span>
                            <img
                                loading="lazy"
                                class="icon"
                                src="@/assets/icons/right-arrow.svg"
                                alt="Icono de flecha hacia adelante"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-4 pb-4 mb-4 other-province" v-if="this.provinces.length > 0">
            <div class="container text-left">
                <div class="row d-flex justify-content-left p-1" id="other_province_links">
                    <h2>Encuentra {{ this.seoBrand.short_desc }} por provincia</h2>
                    <div class="col-12 col-lg-6 col-xxl-4 pb-2" v-for="(province, index) in provinces" :key="index">
                        <router-link
                            :to="{ path: `/coches-segunda-mano/${this.seoBrand.slug}/en/${province.slug}` }"
                            rel="dofollow"
                        >
                            <span>
                                <b>{{ this.seoBrand.short_desc }}</b>
                                de segunda mano en {{ province.short_desc }}
                            </span>
                            <img
                                class="icon"
                                src="@/assets/icons/right-arrow.svg"
                                alt="Icono de flecha hacia adelante"
                            />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="container text-left" id="other_brands_area_container">
                <div class="row gy-3 pt-2 pb-4" id="other_brands_area">
                    <h2>¿Buscas un coche de segunda mano de otra marca?</h2>
                    <BrandCard v-for="(brand, index) in brands" :brand="brand" :key="index" />
                </div>
                <hr />
            </div>
        </div>
        <div class="container-fluid">
            <div class="container text-left">
                <div class="row gy-3 pt-2 pb-4">
                    <p><b>¿Ya has decidido el coche que quieres?</b></p>
                    <p>
                        Sabemos lo difícil que es decidirte, y sabemos lo difícil que es encontrar el coche que buscas.
                        Implica estar mañana, tarde y noche revisando todos los portales de compraventa para encontrar
                        el coche que sueñas a un buen precio: barato. Los anuncios que valen la pena desaparecen muy
                        rápido, y acaba siendo un trabajo agotador.
                    </p>
                    <p>
                        Déjanos esa parte a nosotros. Deja que busquemos por ti en diferentes portales. Déjanos ser tu
                        rastreador favorito de anuncios de coches y furgonetas de segunda mano.
                    </p>
                    <div class="col-md-10 col-lg-8 col-xl-6 mt-3 mb-4 pt-3 search-link" id="search_link">
                        <a href="https://alertacoches.es" rel="dofollow">{{ $t('find_it') }}</a>
                    </div>
                </div>
                <hr />
            </div>
        </div>
        <InfoSection />
        <Footer />
    </div>
</template>
<script>
import { getFilterValues, getFilterValuesBySlug } from '@/application/getFilterValues/getFilterValues';
import { getValuesBasedOnParent } from '@/application/getFilterValues/getFilterValuesBasedOnParent';
import HeadingSection from '@/components/structural/HeadingSection.vue';
import HeadingSectionSkeleton from '@/components/structural/HeadingSectionSkeleton.vue';
import AdCard from '@/components/cards/AdCard.vue';
import SkeletonAdCard from '@/components/cards/SkeletonAdCard.vue';
import LoadingSpinner from '@/components/misc/LoadingSpinner.vue';
import BrandCard from '@/components/cards/BrandCard.vue';
import CollabBanner from '@/components/collab/CollabBanner.vue';
import Footer from '@/components/structural/Footer.vue';
import InfoSection from '@/components/structural/InfoSection.vue';
import { mapState } from 'vuex';
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';
export default {
    components: {
        HeadingSection,
        HeadingSectionSkeleton,
        AdCard,
        SkeletonAdCard,
        LoadingSpinner,
        CollabBanner,
        BrandCard,
        InfoSection,
        Footer,
    },
    data() {
        return {
            loaded: false,
            models: [],
            brands: [],
            provinces: [],
            slugBrand: this.$route.params.brand,
            seoBrand: null,
            collabData: null,
        };
    },
    computed: {
        ...mapState(['ads']),
    },
    methods: {
        async initData() {
            this.seoBrand = await getFilterValuesBySlug(this.slugBrand);
            let seoTitle = `Compra Coches ${this.seoBrand.short_desc} de Segunda Mano | Encuentra el Mejor Precio en AlertaCoches | Rastreador de anuncios`;
            let seoDescription = `Encuentra los mejores anuncios de coches ${this.seoBrand.short_desc} de segunda mano. Compara precios, modelos, y características de vehículos ${this.seoBrand.short_desc} usados en nuestro rastreador. Encuentra el ${this.seoBrand.short_desc} perfecto entre cientos de opciones disponibles en el mercado de segunda mano.`;
            const siteData = reactive({
                title: seoTitle,
                description: seoDescription,
                ogTitle: seoTitle,
                ogDescription: seoDescription,
                keywords:
                    `coches, coches de segunda mano, coches baratos, coches de ocasión, coches usados, coches ${this.seoBrand.short_desc} de segunda mano, ${this.seoBrand.short_desc}, ` +
                    `${this.seoBrand.short_desc} de segunda mano, ${this.seoBrand.short_desc} baratos, ${this.seoBrand.short_desc} de ocasión, ${this.seoBrand.short_desc} usados, ofertas de coches, comparador de coches, ` +
                    `portal de coches de segunda mano, venta de coches usados, vehículos de segunda mano, ` +
                    `comprar ${this.seoBrand.short_desc} usado, rastreador de coches baratos, portal de compraventa ${this.seoBrand.short_desc}`,
            });

            useHead({
                title: computed(() => siteData.title),
                meta: [
                    { name: 'description', content: computed(() => siteData.description) },
                    { name: 'keywords', content: computed(() => siteData.keywords) },
                    { name: 'og:title', content: computed(() => siteData.ogTitle) },
                    { name: 'og:description', content: computed(() => siteData.ogDescription) },
                    { name: 'og:url', content: window.location.href },
                    { name: 'twitter:title', content: computed(() => siteData.ogTitle) },
                    { name: 'twitter:description', content: computed(() => siteData.ogDescription) },
                ],
            });
            this.brands = await getFilterValues(1);
            this.models = await getValuesBasedOnParent(2, this.seoBrand.value_id);
            this.provinces = await getFilterValues(13);
            // this.collabData = {
            //     type: 'banner_seo',
            //     make: this.seoBrand.short_desc,
            // };
        },
    },
    beforeMount() {
        this.initData();
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

p.unimportant-title {
    font-weight: bold;
    font-size: 1.6em;
}

hr {
    background-color: $gray-9;
}
h1,
h2,
h3,
h4,
h5 {
    text-align: left;
}
h2 {
    font-size: 1.5em;
}
.h2-skeleton {
    margin: auto;
    width: 90%;
    height: 1.5em;
    background-color: $gray-9;
    margin-bottom: 1em;
    text-decoration: none !important;
    -webkit-animation: ease flickerAnimation 1s infinite;
    -moz-animation: ease flickerAnimation 1s infinite;
    -o-animation: ease flickerAnimation 1s infinite;
    animation: ease flickerAnimation 1s infinite;
}
p {
    text-align: left;
    font-size: 0.95em;
}
a {
    text-decoration: none;
    color: #2c3e50;
}
.other-model {
    background-color: $secondary-3;
    background-image: linear-gradient(to bottom right, $primary-5, $secondary-4);
    color: $white;
    div {
        text-align: left;
        #other_models_links div {
            &:hover {
                border-radius: $border-radius;
                background-color: $secondary-4;
                -webkit-transition: all 0.4s 0s ease;
                -moz-transition: all 0.4s 0s ease;
                -o-transition: all 0.4s 0s ease;
                transition: all 0.4s 0s ease;
                text-overflow: ellipsis;
            }
            &:hover > img {
                transform: rotate(360deg);
                -webkit-transition: all 0.4s 0s ease;
                -moz-transition: all 0.4s 0s ease;
                -o-transition: all 0.4s 0s ease;
                transition: all 0.4s 0s ease;
            }
            a {
                display: block;
                border-bottom: 1px $primary solid;
                padding: 5px;
                text-decoration: none;
                color: $white;
                border-bottom: 1px $primary solid;
                img {
                    float: right;
                    right: 0;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
.other-model-skeleton {
    background-color: $secondary-3;
    background-image: linear-gradient(to bottom right, $primary-5, $secondary-4);
    color: $primary-5;
    div {
        text-align: left;
        #other_models_links div {
            &:hover {
                border-radius: $border-radius;
                background-color: $secondary-4;
                -webkit-transition: all 0.4s 0s ease;
                -moz-transition: all 0.4s 0s ease;
                -o-transition: all 0.4s 0s ease;
                transition: all 0.4s 0s ease;
                text-overflow: ellipsis;
            }
            &:hover > img {
                transform: rotate(360deg);
                -webkit-transition: all 0.4s 0s ease;
                -moz-transition: all 0.4s 0s ease;
                -o-transition: all 0.4s 0s ease;
                transition: all 0.4s 0s ease;
            }
            a {
                display: block;
                border-bottom: 1px $primary solid;
                padding: 5px;
                text-decoration: none;
                color: $white;
                border-bottom: 1px $primary solid;
                img {
                    float: right;
                    right: 0;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
