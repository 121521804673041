<template>
    <nav class="navbar position-stiky navbar-light p-0 pt-2 pb-2 w-100 m-0" id="simple-nav-bar">
        <div class="container">
            <router-link class="navbar-brand" rel="follow" :to="{ name: 'home', meta: { scrollTop: 0 } }">
                <img
                    src="@/assets/icons/ac-concept-logo-sqr50.png"
                    width="30"
                    height="30"
                    alt="Logotipo de AlertaCoches.es"
                />
                <img
                    src="@/assets/icons/ac-concept-logo-green50.png"
                    width="auto"
                    alt="Logotipo de AlertaCoches.es"
                    class="d-none d-md-inline"
                />
            </router-link>
            <div id="right-side-menu" class="float-right">
                <slot name="link-to-source"></slot>
            </div>
        </div>
    </nav>
    <slot name="collab-modal"></slot>
</template>
<script>
export default {
    props: ['ad'],
    name: 'SimpleNavBar',
    components: {},
    data() {
        return {};
    },
    setup() {},
    methods: {},
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.navbar {
    z-index: 99;
    background-color: $white;
}
.navbar-brand {
    img {
        width: auto !important;
        border-radius: $border-radius;
    }
}
.modal-backdrop {
    background-color: $white;
    opacity: 0.9 !important;
    -webkit-transition: all 0.3s 0s ease;
    -moz-transition: all 0.3s 0s ease;
    -o-transition: all 0.3s 0s ease;
    transition: all 0.3s 0s ease;
}
.modal {
    @include box-shadow;
    -webkit-transition: all 0.3s 0s ease;
    -moz-transition: all 0.3s 0s ease;
    -o-transition: all 0.3s 0s ease;
    transition: all 0.3s 0s ease;
}
.not-found {
    color: $white;
    background-color: $tertiary;
    font-weight: 800;
}
.source {
    padding: 10px 20px;
    background-color: $white !important;
    border-radius: $border-radius;
    border: 1px solid $secondary;
    font-weight: bold;
    color: $secondary;
    @include box-shadow;
}
.whatsapp {
    height: 46px !important;
    background-color: $secondary !important;
    color: $white;
}
//xs
@media (max-width: $breakpoint-xs) {
}
//sm
@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
}
//md
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
}
//lg
@media (min-width: $breakpoint-md) {
}
</style>
