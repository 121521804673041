<template>
    <template v-if="this.collabAdInfo !== null">
        <template v-if="this.collabAdInfo.designId === 'picturePopUp'">
            <div
                class="modal fade"
                id="collabModal"
                tabindex="-1"
                aria-labelledby="collabModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content p-0">
                        <div class="modal-body p-0">
                            <div class="col-12 p-0 collab-pop-up-container">
                                <a
                                    target="_blank"
                                    :href="this.collabAdInfo.buttonUrl"
                                    @click="this.registerCollabAdClick()"
                                >
                                    <img class="picture-banner" :src="`/img/collab${this.collabAdInfo.pictureSrc}`" />
                                </a>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-tertiary" data-bs-dismiss="modal">
                                Seguir buscando
                            </button>
                            <a
                                class="btn btn-primary link-to-source"
                                :href="ad.link"
                                target="_blank"
                                rel="nofollow"
                                :alt="$t('button_link_to_source')"
                                @click="registerUserToSource(this.ad.sourceName)"
                            >
                                Ver en {{ this.ad.sourceName }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div
                class="modal fade"
                id="collabModal"
                tabindex="-1"
                aria-labelledby="collabModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content p-0">
                        <div class="modal-body p-0">
                            <div class="col-12 p-0 collab-pop-up-container">
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                                <div class="collab-pop-up-text p-4" :style="setTextStyles()">
                                    <h3>
                                        {{ collabAdInfo.title }}
                                    </h3>
                                    <div class="separator" :style="setPrimaryBackground()"></div>
                                    <p :style="setTextColor()">
                                        {{ collabAdInfo.paragraph }}
                                    </p>
                                    <a
                                        target="_blank"
                                        :href="collabAdInfo.buttonUrl"
                                        rel="nofollow"
                                        :style="setButtonStyles()"
                                        @click="registerCollabAdClick()"
                                    >
                                        {{ collabAdInfo.buttonText }}
                                    </a>
                                </div>
                                <div class="triangle" :style="setBorderLeftColor()"></div>
                                <div class="collab-pop-up-image">
                                    <img :src="collabAdInfo.imageUrl" :alt="collabAdInfo.customerName" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-tertiary" data-bs-dismiss="modal">
                                Seguir buscando
                            </button>
                            <a
                                class="btn link-to-source source"
                                :href="ad.link"
                                target="_blank"
                                rel="nofollow"
                                :alt="$t('button_link_to_source')"
                                @click="registerUserToSource(this.ad.sourceName)"
                            >
                                Ver en {{ this.ad.sourceName }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </template>
</template>
<script>
import { getCollabResource } from '@/application/getCollabResource/getCollabResource';

export default {
    props: ['collabData', 'ad'],
    data() {
        return {
            collabAdInfo: null,
        };
    },
    methods: {
        setPrimaryBackground() {
            return {
                'background-color': this.collabAdInfo.primaryHexColor,
            };
        },
        setSecondaryBackground() {
            return {
                'background-color': this.collabAdInfo.secondaryHexColor,
            };
        },
        setBorderLeftColor() {
            return {
                'border-left-color': this.collabAdInfo.secondaryHexColor,
            };
        },
        setTextColor() {
            return {
                color: this.collabAdInfo.textColor,
            };
        },
        setButtonColor() {
            return {
                color: this.collabAdInfo.buttonTextColor,
            };
        },
        setTextStyles() {
            return this.jsonConcat(this.setSecondaryBackground(), this.setTextColor());
        },
        setButtonStyles() {
            return this.jsonConcat(this.setPrimaryBackground(), this.setButtonColor());
        },
        jsonConcat(o1, o2) {
            for (var key in o2) {
                o1[key] = o2[key];
            }
            return o1;
        },
        registerCollabAdClick() {
            gtag('event', 'ac_click_ad', {
                type: 'pop-up',
                customerName: this.collabAdInfo.customerName,
                contentId: this.collabAdInfo.contentId,
                make: this.collabData.make,
                model: this.collabData.model,
                province: this.collabData.province,
            });
        },
        registerCollabAdDisplay() {
            gtag('event', 'ac_display_ad', {
                type: 'pop-up',
                customerName: this.collabAdInfo.customerName,
                contentId: this.collabAdInfo.contentId,
                make: this.collabData.make,
                model: this.collabData.model,
                province: this.collabData.province,
            });
        },
        registerUserToSource(sourceName) {
            gtag('event', 'ac_visit_source', {
                make: this.collabAdInfo.make,
                model: this.collabAdInfo.model,
                sourceName: sourceName,
            });
        },
    },
    async beforeMount() {
        this.collabAdInfo = await getCollabResource(this.collabData, window.innerWidth < 768);
    },
    mounted() {
        if (this.collabAdInfo !== null) {
        }
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.modal-backdrop {
    display: none;
}
.modal-content {
    @include popup-shadow;
    border-radius: $border-radius-deep;
    .modal-body {
        border-top-left-radius: $border-radius-deep;
        border-top-right-radius: $border-radius-deep;
        img {
            border-top-left-radius: $border-radius-deep;
            border-top-right-radius: $border-radius-deep;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}
.link-to-source {
    background-color: $secondary !important;
    color: $white !important;
    padding: 12px 18px !important;
    font-weight: 700 !important;
    border: 0;
    margin-left: 10px !important;
}
.collab-pop-up-container {
    position: relative;
    overflow: hidden;
    margin: auto;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
    .collab-pop-up-text {
        display: inline;
        position: relative;
        float: left;
        height: 400px;
        z-index: 10;
        width: 45%;
        background-color: #0061bf;
        color: $white;
        h3 {
            font-size: 23px;
            margin: 10px;
        }
        .separator {
            width: 25%;
            height: 0;
            border: 3px solid #fd7d00;
        }
        p {
            margin: 10px;
            color: $white;
        }
        a {
            position: absolute;
            float: left;
            left: 30px;
            bottom: 20px;
            padding: 10px;
            background-color: #fd7d00;
            border-radius: $border-radius;
            color: $white;
            text-decoration: none;
            font-weight: bold;
            transition: all 0.7s ease;
            &:hover {
                background-color: #e87400;
                transition: all 0.7s ease;
            }
        }
    }
    .triangle {
        display: inline;
        position: relative;
        float: left;
        height: 400px;
        z-index: 10;
        width: 0;
        border-left: 100px solid #0061bf;
        border-bottom: 400px solid transparent;
    }
    .collab-pop-up-image {
        position: relative;
        top: 0;
        margin-left: 50%;
        margin-top: -400px;
        float: right;
        z-index: 0;
        height: 100%;
        width: 55%;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
@media (max-width: $breakpoint-md) {
    .collab-container {
        position: relative;
        height: 400px;
        border-radius: $border-radius;
        overflow: hidden;
        margin: auto;
        .collab-text {
            position: relative;
            float: left;
            margin-top: 260px;
            height: 150px;
            z-index: 10;
            width: 100%;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            background-color: #888;
            color: #000;
            h3 {
                font-size: 14px;
            }
            .separator {
                width: 25%;
                height: 4px;
                background-color: #fff;
            }
            p {
                font-size: 12px;
                margin-top: 5px;
                color: #000;
            }
            a {
                position: absolute;
                left: 8px;
                bottom: 20px;
                padding: 5px;
                font-size: 12px;
                background-color: #fff;
                border-radius: $border-radius;
                color: #000;
                text-decoration: none;
                font-weight: bold;
            }
        }
        .triangle {
            position: relative;
            float: left;
            margin-top: -190px;
            width: 100%;
            z-index: 10;
            width: 0;
            height: 0;
            border-left: 400px solid #888;
            border-top: 40px solid transparent;
        }
        .collab-image {
            position: relative;
            height: 400px;
            width: 100%;
            top: 0;
            margin-top: -470px;
            float: left;
            z-index: 0;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }
    }
}
@media (min-width: $breakpoint-md) {
    .collab-container {
        position: relative;
        height: 300px;
        border-radius: $border-radius;
        overflow: hidden;
        margin: auto;
        .collab-text {
            display: inline;
            position: relative;
            float: left;
            height: 300px;
            z-index: 10;
            width: 45%;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            background-color: #888;
            color: #000;
            h3 {
                font-size: 23px;
                margin: 10px;
            }
            .separator {
                width: 25%;
                height: 5px;
                background-color: #fff;
            }
            p {
                margin: 10px;
                color: #000;
            }
            a {
                position: absolute;
                left: 30px;
                bottom: 20px;
                padding: 10px;
                background-color: #fff;
                border-radius: $border-radius;
                color: #000;
                text-decoration: none;
                font-weight: bold;
            }
        }
        .triangle {
            display: inline;
            position: relative;
            float: left;
            height: 300px;
            z-index: 10;
            width: 0;
            height: 0;
            border-left: 100px solid #888;
            border-bottom: 300px solid transparent;
        }
        .collab-image {
            position: relative;
            top: 0;
            margin-left: 50%;
            margin-top: -300px;
            float: right;
            z-index: 0;
            height: 100%;
            width: 55%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }
    }
}
</style>
