const validate = {
    name: [
        (value) => {
            if (value) return true;

            return 'Debes escribir tu nombre';
        },
        (value) => {
            if (value.length >= 3) return true;

            return 'Tu nombre completo debe tener al menos 3 caracteres';
        },
        (value) => {
            if (value.length <= 20) return true;

            return 'Tu nombre completo debe tener un máximo de 20 caracteres';
        },
    ],
    email: [
        (value) => {
            if (value) return true;

            return 'Debes escribir tu email';
        },
        (value) => {
            if (/.+@.+\..+/.test(value)) return true;

            return 'Debes escribir un email correcto';
        },
    ],
    password: [
        (value) => {
            if (value) return true;

            return 'Debes escribir tu contraseña';
        },
        (value) => {
            if (value.length >= 6) return true;

            return 'La contraseña debe tener al menos 6 caracteres';
        },
        (value) => {
            if (value.length <= 20) return true;

            return 'La contraseña debe tener un máximo de 20 caracteres';
        },
    ],
    phoneNumber: [
        (value) => {
            if (value) return true;

            return 'Debes escribir tu número de teléfono';
        },
        (value) => {
            if (/^\d{9}$/.test(value)) return true;

            return 'Debes escribir un número de teléfono correcto';
        },
    ],
    vinPlateNumber: [
        (value) => {
            if (value) return true;

            return 'Debes escribir tu una matrícula o número de bastidor';
        },
        (value) => {
            value = value.toUpperCase();
            if (/^\d{4}[B-DF-HJ-NPR-TV-Z]{3}$/.test(value)) return true;
            if (/\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/gm.test(value)) return true;
            return 'Debes escribir una matrícula o número de bastidor correcto';
        },
    ],
    message: [
        (value) => {
            if (value) return true;
            return 'Debes escribir el mensaje que quieres enviar';
        },
    ],
    terms: [
        (value) => {
            if (value) return true;

            return 'Debes aceptar las condiciones del servicio para poder registrar tu cuenta';
        },
    ],
    termsContact: [
        (value) => {
            if (value) return true;

            return 'Debes aceptar las condiciones del servicio para poder enviar tu mensaje';
        },
    ],
};
export { validate };
