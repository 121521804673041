<template>
    <template v-if="this.collabAdInfo !== null">
        <template v-if="this.collabAdInfo.designId === 'pictureBanner'">
            <div class="col-12 p-0 picture-banner-container m-0">
                <a target="_blank" :href="this.collabAdInfo.buttonUrl" @click="registerCollabAdClick()">
                    <img
                        class="picture-banner"
                        :src="`/img/collab${this.collabAdInfo.pictureSrc}`"
                        :alt="this.collabAdInfo.customerName"
                    />
                </a>
            </div>
        </template>
        <template v-else>
            <div class="col-12 col-xl-10 p-0 collab-container">
                <div class="collab-text p-2" :style="setTextStyles()">
                    <h3>
                        {{ this.collabAdInfo.title }}
                    </h3>
                    <div class="separator" :style="setPrimaryBackground()"></div>
                    <p :style="setTextColor()">
                        {{ this.collabAdInfo.paragraph }}
                    </p>
                    <a
                        target="_blank"
                        :href="this.collabAdInfo.buttonUrl"
                        rel="nofollow"
                        :style="setButtonStyles()"
                        @click="registerCollabAdClick()"
                    >
                        {{ this.collabAdInfo.buttonText }}
                    </a>
                </div>
                <div class="triangle" :style="setBorderLeftColor()"></div>
                <div class="collab-image">
                    <img :src="this.collabAdInfo.imageUrl" :alt="collabData.customerName" />
                </div>
            </div>
        </template>
    </template>
</template>
<script>
import { getCollabResource } from '@/application/getCollabResource/getCollabResource';

export default {
    props: ['collabData'],
    data() {
        return {
            collabAdInfo: null,
        };
    },
    methods: {
        setPrimaryBackground() {
            return {
                'background-color': this.collabAdInfo.primaryHexColor,
            };
        },
        setSecondaryBackground() {
            return {
                'background-color': this.collabAdInfo.secondaryHexColor,
            };
        },
        setBorderLeftColor() {
            return {
                'border-left-color': this.collabAdInfo.secondaryHexColor,
            };
        },
        setTextColor() {
            return {
                color: this.collabAdInfo.textColor,
            };
        },
        setButtonColor() {
            return {
                color: this.collabAdInfo.buttonTextColor,
            };
        },
        setTextStyles() {
            return this.jsonConcat(this.setSecondaryBackground(), this.setTextColor());
        },
        setButtonStyles() {
            return this.jsonConcat(this.setPrimaryBackground(), this.setButtonColor());
        },
        jsonConcat(o1, o2) {
            for (var key in o2) {
                o1[key] = o2[key];
            }
            return o1;
        },
        registerCollabAdClick() {
            gtag('event', 'ac_click_ad', {
                type: this.collabData.type || 'banner',
                customerName: this.collabAdInfo.customerName,
                contentId: this.collabAdInfo.contentId,
                make: this.collabData.make,
                model: this.collabData.model,
                province: this.collabData.province,
            });
        },
    },
    setup() {},
    async beforeMount() {
        this.collabAdInfo = await getCollabResource(this.collabData);
        gtag('event', 'ac_display_ad', {
            type: this.collabData.type || 'banner',
            customerName: this.collabAdInfo.customerName,
            contentId: this.collabAdInfo.contentId,
            make: this.collabData.make,
            model: this.collabData.model,
            province: this.collabData.province,
        });
    },
    mount() {},
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.picture-banner-container {
    display: grid;
    border-radius: $border-radius;
    overflow: hidden;
    justify-content: center;
    .picture-banner {
        width: 100% !important;
        max-width: 800px;
        height: auto !important;
        margin: 0 auto;
        border-radius: $border-radius;
    }
}
@media (max-width: $breakpoint-md) {
    .collab-container {
        position: relative;
        height: 400px;
        border-radius: $border-radius;
        overflow: hidden;
        margin: auto;
        .collab-text {
            position: relative;
            float: left;
            margin-top: 260px;
            height: 150px;
            z-index: 10;
            width: 100%;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            background-color: #888;
            color: #000;
            h3 {
                font-size: 14px;
            }
            .separator {
                width: 25%;
                height: 4px;
                background-color: #fff;
            }
            p {
                font-size: 12px;
                margin-top: 5px;
                color: #000;
            }
            a {
                position: absolute;
                left: 8px;
                bottom: 20px;
                padding: 5px;
                font-size: 12px;
                background-color: #fff;
                border-radius: $border-radius;
                color: #000;
                text-decoration: none;
                font-weight: bold;
            }
        }
        .triangle {
            position: relative;
            float: left;
            margin-top: -190px;
            width: 100%;
            z-index: 10;
            width: 0;
            height: 0;
            border-left: 400px solid #888;
            border-top: 40px solid transparent;
        }
        .collab-image {
            position: relative;
            height: 400px;
            width: 100%;
            top: 0;
            margin-top: -470px;
            float: left;
            z-index: 0;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }
    }
}
@media (min-width: $breakpoint-md) {
    .collab-container {
        position: relative;
        height: 300px;
        border-radius: $border-radius;
        overflow: hidden;
        margin: auto;
        .collab-text {
            display: inline;
            position: relative;
            float: left;
            height: 300px;
            z-index: 10;
            width: 45%;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            background-color: #888;
            color: #000;
            h3 {
                font-size: 23px;
                margin: 10px;
            }
            .separator {
                width: 25%;
                height: 5px;
                background-color: #fff;
            }
            p {
                margin: 10px;
                color: #000;
            }
            a {
                position: absolute;
                left: 30px;
                bottom: 20px;
                padding: 10px;
                background-color: #fff;
                border-radius: $border-radius;
                color: #000;
                text-decoration: none;
                font-weight: bold;
            }
        }
        .triangle {
            display: inline;
            position: relative;
            float: left;
            height: 300px;
            z-index: 10;
            width: 0;
            height: 0;
            border-left: 100px solid #888;
            border-bottom: 300px solid transparent;
        }
        .collab-image {
            position: relative;
            top: 0;
            margin-left: 50%;
            margin-top: -300px;
            float: right;
            z-index: 0;
            height: 100%;
            width: 55%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }
    }
}
</style>
