<template>
    <router-view v-slot="{ Component, route }">
        <transition
            mode="out-in"
            :duration="300"
            :enter-active-class="getEnterClass(route)"
            :leave-active-class="getLeaveClass(route)"
        >
            <component :is="Component" :key="$route.path"></component>
        </transition>
    </router-view>
    <ActionBar v-if="$route.name !== 'singleAd'" />
    <FeedbackInterface></FeedbackInterface>
    <CookieConsent></CookieConsent>
</template>
<script>
import ActionBar from '@/components/structural/ActionBar.vue';
import CookieConsent from '@/components/structural/CookieConsent/CookieConsent.vue';
import FeedbackInterface from '@/components/structural/FeedbackInterface.vue';
import 'animate.css';
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';

export default {
    setup() {
        const siteData = reactive({
            keywords:
                'coches, segunda mano, coches de segunda mano, coches baratos, coches de ocasión, coches usados, rastreador de coches, rastreador de ofertas, portal de compraventa',
        });

        useHead({
            meta: [
                { name: 'keywords', content: computed(() => siteData.keywords) },
                { name: 'robots', content: 'index, follow' },
                { name: 'author', content: 'AlertaCoches.es' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
                { name: 'theme-color', content: '#ffffff' },
                { name: 'msapplication-TileColor', content: '#ffffff' },
                { name: 'msapplication-TileImage', content: '/src/assets/icons/ac-concept-logo-green50.png' },
                { name: 'msapplication-config', content: '/browserconfig.xml' },
                { name: 'apple-mobile-web-app-capable', content: 'yes' },
                { name: 'apple-mobile-web-app-status-bar-style', content: 'white' },
                { name: 'apple-mobile-web-app-title', content: 'AlertaCoches' },
                { name: 'application-name', content: 'AlertaCoches' },
                { name: 'og:title', content: computed(() => siteData.title) },
                { name: 'og:description', content: computed(() => siteData.description) },
                { name: 'og:image', content: '/src/assets/icons/android-icon-192x192.png' },
                { name: 'og:url', content: 'https://alertacoches.es' },
                { name: 'og:type', content: 'website' },
                { name: 'og:site_name', content: 'AlertaCoches' },
                { name: 'og:locale', content: 'es_ES' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:site', content: '@alertacoches' },
                { name: 'twitter:creator', content: '@alertacoches' },
                { name: 'twitter:title', content: computed(() => siteData.title) },
                { name: 'twitter:description', content: computed(() => siteData.description) },
                { name: 'twitter:image', content: '/src/assets/icons/android-icon-192x192.png' },
                { name: 'Pragma', content: 'no-cache, max-age=2628000' },
                { name: 'Cache-Control', content: 'no-cache, no-store, must-revalidate, max-age=2628000' },
            ],
        });
    },
    methods: {
        getEnterClass(route) {
            const transitionType = this.getTransitionType(route);

            if (transitionType === 'slideLeft') {
                return 'animate__animated animate__fadeInRight';
            }

            if (transitionType === 'slideRight') {
                return 'animate__animated animate__fadeInLeft';
            }

            return transitionType;
        },
        getLeaveClass(route) {
            const transitionType = this.getTransitionType(route);

            if (transitionType === 'slideLeft') {
                return 'animate__animated animate__fadeOutLeft';
            }

            if (transitionType === 'slideRight') {
                return 'animate__animated animate__fadeOutRight';
            }

            return 'fade';
        },
        getTransitionType(route) {
            if (route.meta.buttonPositionIndex == 100 && route.meta.previousButtonPositionIndex == 100) {
                if (route.query.navigateTo === 'right') {
                    return 'slideLeft';
                }
                if (route.query.navigateTo === 'left') {
                    return 'slideRight';
                }
            }

            if (route.meta.buttonPositionIndex == 100 && route.meta.previousButtonPositionIndex != 100) {
                return 'slideLeft';
            }

            if (route.meta.buttonPositionIndex != 100 && route.meta.previousButtonPositionIndex == 100) {
                return 'slideRight';
            }

            if (route.meta.buttonPositionIndex > route.meta.previousButtonPositionIndex) {
                return 'slideLeft';
            }

            if (route.meta.buttonPositionIndex < route.meta.previousButtonPositionIndex) {
                return 'slideRight';
            }

            return 'fade';
        },
    },
    components: {
        CookieConsent,
        FeedbackInterface,
        ActionBar,
    },
    mounted() {
        this.$i18n.locale = navigator.language;
    },
};
</script>
<style lang="scss">
@import '@/styles/main.scss';
.page {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-family: $main-font;
}
// Type of transition
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-out;
}
</style>
