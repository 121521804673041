import { getFavourites } from '@/infrastructure/services/favouriteHandler';
import store from '@/infrastructure/persistence/store/vuex';
async function getListOfFavourites() {
    const vuexstore = store.repository;
    try {
        vuexstore.dispatch('favourites/clear');
        const ads = await getFavourites();
        vuexstore.dispatch('favourites/setFavourites', ads['data']);

        return ads;
    } catch (error) {
        vuexstore.dispatch('favourites/clear');
        throw Error(error.message);
    }
}

export { getListOfFavourites };
