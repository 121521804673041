export default [
    {
        designId: 'native',
        contentId: 'El secreto para no comprar un coche con km trucados',
        title: '¿Todavía no conoces a Needcarhelp?',
        paragraph: '¡Haz clic aquí que esto te interesa!',
        pictureSrc: '/NeedCarHelp/native/El-secreto-par-no-comprar.png',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=native&utm_campaign=secreto_para_no',
    },
    {
        designId: 'native',
        contentId: 'Cómo comprar un coche con km verificados',
        title: 'Revisándolo antes con un experto para evitar sorpresas',
        paragraph: '¡Descubre Needcarhelp!',
        pictureSrc: '/NeedCarHelp/native/Como-comprar-coche.png',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=native&utm_campaign=como_comprar',
    },
    {
        designId: 'native',
        contentId: 'Revisamos tu nuevo coche. ¡Conócenos!',
        title: 'Verificamos km y detectamos manipulaciones.',
        paragraph: '¡Te asesoramos!',
        pictureSrc: '/NeedCarHelp/native/Revisamos-tu-nuevo-coche.png',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=native&utm_campaign=revisamos',
    },
    {
        designId: 'native',
        contentId: 'Compra el coche donde sea, nosotros vamos a revisarlo.',
        title: 'Verificamos km y detectamos manipulaciones.',
        paragraph: '¡Toma la mejor decisión!',
        pictureSrc: '/NeedCarHelp/native/Compra-donde-sea-nosotros-vamos.png',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=native&utm_campaign=donde_sea',
    },
    {
        designId: 'native',
        contentId: 'El secreto para comprar un coche sin manipulaciones',
        title: 'Se llama Needcarhelp y han revisado ya más de 12.000 coches por toda España.',
        paragraph: '¡Conócelos aquí!',
        pictureSrc: '/NeedCarHelp/native/El-secreto-para-comprar.png',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=native&utm_campaign=secreto',
    },
    {
        designId: 'native',
        contentId: 'Revisa el coche con un experto por 199 €',
        title: 'Informe completo de 150 puntos + Fotos y Vídeos de todos los detalles + Asesoramiento.',
        paragraph: '¡Conoce Needcarhelp!',
        pictureSrc: '/NeedCarHelp/native/Revisa-el-coche-199.png',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=native&utm_campaign=revisa ',
    },
];
