import { getApiParserBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

async function getBusinessPageConfiguration(businessSlug) {
    try {
        const response = await HttpClient.get(`${getApiParserBaseUrl()}/${businessSlug}/page-config`);

        return response.data;
    } catch (err) {
        throw err;
    }
}

async function sendBusinessContactMessage(messageInfo) {
    try {
        const response = await HttpClient.post(
            `${getApiParserBaseUrl()}/${messageInfo.customerSlug}/contact`,
            messageInfo,
        );

        return response;
    } catch (err) {
        throw err;
    }
}

async function getBusinessReviews(businessSlug) {
    try {
        const response = await HttpClient.get(`${getApiParserBaseUrl()}/${businessSlug}/reviews`);

        if (response.status !== 200) {
            return [];
        }

        return response.data;
    } catch (err) {
        throw err;
    }
}

export { getBusinessPageConfiguration, sendBusinessContactMessage, getBusinessReviews };
