import { countAnuncioService } from '@/infrastructure/services/searchAnuncioService';
import { filterNulls } from '@/infrastructure/services/helperService';

import store from '@/infrastructure/persistence/store/vuex';

async function countAnuncio(query) {
    const filteredQuery = filterNulls(query);
    try {
        const countedAds = await countAnuncioService(filteredQuery);

        return countedAds;
    } catch (error) {
        return 0;
    }
}

export { countAnuncio };
