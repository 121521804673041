<template>
    <router-link :to="{ path: getURL }" class="col-12 col-md-5 col-lg-4 col-xxl-3" rel="dofollow">
        <div class="back-container p-2 brand-card">
            <img :src="getImage" :alt="`Enlace al listado de ${brand.short_desc} de segunda mano`" loading="lazy" />
            <div class="front-container">
                <h2>
                    <b>{{ brand.short_desc }}</b>
                    <br />
                    de segunda mano
                </h2>
            </div>
        </div>
    </router-link>
</template>
<script>
export default {
    props: ['brand'],
    data() {
        return {
            showImg: true,
        };
    },
    computed: {
        getImage() {
            return `/img/marca/${this.getFormattedName(this.brand.short_desc)}-sm.jpg`;
        },
        getURL() {
            return '/coches-segunda-mano/' + this.brand.slug.toLowerCase();
        },
    },
    methods: {
        getFormattedName(brand_name) {
            return brand_name.replace(' ', '-').replace('ë', 'e').toLowerCase();
        },
    },
    setup() {},
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.brand-card {
    position: relative;
    @include box-shadow;
    border-radius: $border-radius;
    height: 200px;
    transition: all 0.1s ease;
    overflow: hidden;
    vertical-align: middle;
    object-fit: cover;
    filter: grayscale(10%);
    &:hover {
        cursor: pointer;
        @include card-box-shadow;
        transition: all 0.1s ease;
        filter: grayscale(0%);
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .front-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        background-color: rgb(0, 0, 0, 0.3);
        h2 {
            text-align: center;
            margin: auto;
            margin-top: 45px;
            color: $white;
        }
    }
}
</style>
