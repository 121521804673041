import { doLogout } from '@/infrastructure/services/authService';

import store from '@/infrastructure/persistence/store/vuex';

async function logout() {
    const vuexstore = store.repository;
    try {
        const response = await doLogout();

        if (response.status === 200) {
            vuexstore.dispatch('user/logOut', response.data.user);

            return true;
        }

        if (response.status >= 500) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Error inesperado al cerrar sesión. Vuelve a intentarlo más tarde.',
            );
            return false;
        }

        vuexstore.dispatch('feedbackInterface/setErrorResponse', response.data.message);
        return false;
    } catch (error) {
        return 0;
    }
}

export { logout };
