import { getBusinessPageConfiguration } from '@/infrastructure/services/business';
import { getImageByVariant } from '@/infrastructure/services/mediaImage';

async function getPageConfiguration(businessType, businessSlug) {
    try {
        const pageConfig = await getBusinessPageConfiguration(businessSlug);
        pageConfig.logoUrl = getImageByVariant(pageConfig.logoUrl);
        pageConfig.backgroundUrl = getImageByVariant(pageConfig.backgroundUrl);

        return pageConfig;
    } catch (error) {
        throw Error(error.message);
    }
}

export { getPageConfiguration };
