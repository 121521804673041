<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mt-6">
                <v-divider></v-divider>
                <h2>Ya confían en nosotros</h2>
                <p>
                    Descubre a las marcas y empresas que ya confían en nosotros. Nuestro compromiso y excelencia nos han
                    permitido colaborar con clientes y asociados de primer nivel. Únete a una red de confianza y
                    crecimiento.
                </p>
                <v-slide-group class="p-2 d-flex justify-center">
                    <v-slide-group-item v-for="sponsor in sponsors" :key="sponsor" class="d-flex align-center">
                        <a :href="sponsor.href" target="_blank">
                            <v-card
                                class="m-2 mr-3 mb-4 sponsor-card"
                                height="fit-content"
                                max-width="270"
                                rounded="xl"
                                elevation="0"
                            >
                                <v-card-text
                                    class="text-medium-emphasis text-darken-3 mt-3 mb-3"
                                    :id="`logo-${sponsor.id}`"
                                >
                                    <img
                                        :src="sponsor.image"
                                        :alt="sponsor.description"
                                        :style="sponsor.name == 'Carfax' ? 'margin-top: 10px' : ''"
                                        loading="lazy"
                                    />
                                </v-card-text>
                            </v-card>
                        </a>
                    </v-slide-group-item>
                </v-slide-group>
                <v-divider></v-divider>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlatformSponsors',
    props: {
        customer: {
            type: Object,
            required: true,
        },
        brandStyle: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            sponsors: [
                {
                    id: 1,
                    name: 'Carfax',
                    href: 'https://www.carfax.eu/es/partner/alertacoches',
                    image: 'https://imagedelivery.net/F646Wun-eua00pA0NmkORQ/3acfe32c-6c41-4520-cfa2-0cb75f121b00/mobile',
                    description: 'Genera un informe con el historial del coche con Carfax.',
                },
                {
                    id: 2,
                    name: 'Revisamoselcoche',
                    href: 'https://revisamoselcoche.com/',
                    image: 'https://revisamoselcoche.com/admin/images/settings/logo//230x74/405KG2rY2L.webp',
                    description: 'Calcula el coste de la revisión de tu coche con Revisamoselcoche',
                },
                {
                    id: 3,
                    name: 'Rattix',
                    href: 'https://alertacoches.es/compraventa/rattix',
                    image: 'https://imagedelivery.net/F646Wun-eua00pA0NmkORQ/9cb8f23d-2aae-4de3-e316-7c2cfecaf000/public',
                    description: 'Compra tu siguiente coche en Barcelona con Rattix.',
                },
            ],
        };
    },
    methods: {},
    async created() {},
};
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
p {
    color: #666;
    font-size: 1em;
}
.sponsor-card img {
    margin: auto 0;
    width: 110px;
    height: auto;
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    p {
        font-size: 1em;
    }
    h2 {
        margin-left: 0px;
    }
    .sponsor-card img {
        margin: auto 0;
        width: 170px;
        height: auto;
    }
}
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    p {
        font-size: 1.2em;
    }
    h2 {
        margin-left: 0px;
    }
    .sponsor-card img {
        margin: auto 0;
        width: 170px;
        height: auto;
    }
}
@media (min-width: $breakpoint-lg) {
    .sponsor-card img {
        margin: auto 0;
        width: 170px;
        height: auto;
    }
}
</style>
