import { getValuesServiceBasedOnParent } from '@/infrastructure/services/getValuesServiceBasedOnParent';
async function getValuesBasedOnParent(filter_id, parent_id) {
    try {
        const values = await getValuesServiceBasedOnParent(filter_id, parent_id);
        return values;
    } catch (error) {
        throw Error(error.message);
    }
}

export { getValuesBasedOnParent };
