<template>
    <div class="page" ref="resetPassword">
        <SimpleNavBar></SimpleNavBar>
        <div class="container-fluid">
            <div class="container col-12 col-md-8 col-xl-6 col-xxl-4 login-form login-components">
                <h1 class="login-header">Reestablecer contraseña</h1>
                <v-form @submit.prevent id="reset-password-form">
                    <v-text-field
                        type="password"
                        v-model="userPassword"
                        :rules="passwordRules"
                        label="Contraseña"
                        theme="light"
                        variant="solo"
                        class="mb-2"
                    ></v-text-field>
                    <v-text-field
                        type="password"
                        v-model="userPasswordConfirm"
                        :rules="passwordRules"
                        label="Confirma contraseña"
                        theme="light"
                        variant="solo"
                        class="mb-2"
                    ></v-text-field>
                    <v-btn
                        type="submit"
                        @click="savePasswordChanges"
                        :loading="this.isChangingPassword"
                        block
                        class="mt-2 mb-2 btn btn-primary"
                    >
                        Guardar nueva contraseña
                    </v-btn>
                </v-form>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/structural/Footer.vue';
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import { validate } from '@/infrastructure/services/validator';
import { sendResetPasswordEmail } from '@/application/auth/sendResetPasswordEmail';
import { editLoggedOutPassword } from '@/application/user/edit';

export default {
    components: {
        SimpleNavBar,
        Footer,
    },
    data() {
        return {
            userPassword: '',
            userPasswordConfirm: '',
            token: '',
            userEmail: '',
            passwordRules: validate.password,
            isChangingPassword: false,
        };
    },
    async beforeMount() {
        this.token = this.$route.query.token;
        this.userEmail = this.$route.query.email;
    },
    methods: {
        async savePasswordChanges() {
            this.isChangingPassword = true;
            const passwordData = {
                password: this.userPassword,
                password_confirmation: this.userPasswordConfirm,
                token: this.token,
                email: this.userEmail,
            };

            const response = await editLoggedOutPassword(passwordData);

            if (response) {
                this.$router.push({ name: 'login' });
            }

            this.isChangingPassword = false;
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
</style>
