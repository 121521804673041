<template>
    <div class="main-search-toggle-container w-100 fluid">
        <v-row id="main_search_toggle" class="main-search-toggle rounded-xl">
            <div
                id="simple_search_toggle"
                class="simple-search-toggle"
                @click="this.simpleFiltersAreVisible = !this.simpleFiltersAreVisible"
            >
                <div class="icon">
                    <v-icon icon="mdi-magnify" size="small"></v-icon>
                </div>
                <p id="brand_model_display">¿Qué coche buscas?</p>
                <p id="year_display">
                    <template v-if="brand === null && model === null">Marca / Modelo / Fecha publicación</template>
                    <template v-if="brand !== null && model === null">
                        Cualquier {{ this.getShortDesc(this.brand, this.brandOptions) }}
                    </template>
                    <template v-if="brand !== null && model !== null">
                        {{ this.getShortDesc(this.brand, this.brandOptions) }}
                        {{ this.getShortDesc(this.model, this.modelOptions) }}
                    </template>
                </p>
            </div>
            <v-dialog
                id="simple_filters_overlay"
                class="align-center justify-center filters-overlay"
                scrollable
                transition="dialog-bottom-transition"
                :model-value="simpleFiltersAreVisible"
            >
                <v-toolbar density="compact" color="white">
                    <v-toolbar-title class="filter-top-title ml-5">Filtros</v-toolbar-title>
                    <v-btn icon @click="this.simpleFiltersAreVisible = !this.simpleFiltersAreVisible">
                        <v-icon size="small">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div id="simple_filters_container" class="filters-container">
                    <div id="simple_form_container" class="simple-form-container">
                        <v-autocomplete
                            clearable
                            id="brand_input"
                            name="brand_input"
                            v-model="brand"
                            :items="brandOptions"
                            item-title="short_desc"
                            item-value="value_id"
                            label="Marca"
                            theme="light"
                            variant="solo"
                            class="input pa-5 pb-0"
                            @update:modelValue="
                                getModelListByBrand();
                                countResults();
                            "
                        ></v-autocomplete>
                        <v-autocomplete
                            clearable
                            id="model_input"
                            name="model_input"
                            v-model="model"
                            :items="modelOptions"
                            item-title="short_desc"
                            item-value="value_id"
                            label="Modelo"
                            theme="light"
                            variant="solo"
                            class="input pa-5 pt-0 pb-0"
                            :disabled="modelOptions.length === 0"
                            @update:modelValue="countResults()"
                        ></v-autocomplete>
                        <v-btn
                            v-if="!this.isAuthenticated"
                            id="filter_date_disabled"
                            label="Publicados hace"
                            size="x-large"
                            append-icon="mdi-triangle-down"
                            class="input time-button text-none ml-5 mr-5 mb-5"
                            @click.native="this.$store.dispatch('user/setShowLoginModal', true)"
                        >
                            <span>Publicados hace</span>
                        </v-btn>
                        <v-btn
                            v-else-if="!this.isVerified"
                            id="filter_date_disabled"
                            label="Publicados hace"
                            size="x-large"
                            append-icon="mdi-triangle-down"
                            class="input time-button text-none ml-5 mr-5 mb-5"
                            @click.native="this.$store.dispatch('user/setShowVerifyModal', true)"
                        >
                            <span>Publicados hace</span>
                        </v-btn>
                        <v-select
                            v-else
                            clearable
                            id="filter_date"
                            name="filter_date"
                            v-model="date"
                            :items="dateOptions"
                            item-title="short_desc"
                            item-value="value_id"
                            label="Publicados hace"
                            theme="light"
                            variant="solo"
                            class="input pa-5 pt-0 pb-0"
                            @update:modelValue="countResults()"
                        ></v-select>
                        <v-btn
                            size="x-large"
                            append-icon="mdi-tune-variant"
                            color="#EEE"
                            class="other-filter-button text-none ml-5 mr-5"
                            @click="
                                this.advancedFiltersAreVisible = !this.advancedFiltersAreVisible;
                                this.simpleFiltersAreVisible = !this.simpleFiltersAreVisible;
                            "
                        >
                            <span>Otros filtros</span>
                        </v-btn>
                    </div>
                    <div class="overlay-footer">
                        <v-btn
                            size="x-large"
                            density="default"
                            prepend-icon="mdi-magnify"
                            class="btn-primary text-none rounded-xl"
                            @click="doSearch() && (this.simpleFiltersAreVisible = !this.simpleFiltersAreVisible)"
                        >
                            <template v-if="this.foundAds === 0 && !this.hasModifiedSearch">Ver todo</template>
                            <template v-else>
                                Ver
                                <span>{{ this.foundAds }}</span>
                                coches
                            </template>
                        </v-btn>
                        <v-btn
                            size="x-large"
                            variant="text"
                            density="default"
                            class="text-none"
                            @click="this.simpleFiltersAreVisible = !this.simpleFiltersAreVisible"
                        >
                            Cancelar
                        </v-btn>
                    </div>
                </div>
            </v-dialog>
            <div
                id="complex_search_toggle"
                class="complex-search-toggle"
                @click="this.advancedFiltersAreVisible = !this.advancedFiltersAreVisible"
            >
                <v-badge color="red" v-if="this.nrAppliedFilters > 0" dot>
                    <div class="button">
                        <v-icon icon="mdi-tune-variant" size="x-small" class="mx-auto"></v-icon>
                    </div>
                </v-badge>
                <div class="button" v-else>
                    <v-icon icon="mdi-tune-variant" size="x-small" class="mx-auto"></v-icon>
                </div>
            </div>
        </v-row>
    </div>
    <div class="container-fluid text-left main-desktop-search-toggle">
        <div class="container">
            <div class="row">
                <div class="col-3 col-lg-2 p-1 search-components">
                    <v-autocomplete
                        clearable
                        id="brand_input"
                        name="brand_input"
                        v-model="brand"
                        :items="brandOptions"
                        item-title="short_desc"
                        item-value="value_id"
                        label="Marca"
                        theme="light"
                        variant="solo"
                        @update:modelValue="
                            getModelListByBrand();
                            countResults();
                        "
                        :loading="!brandOptions"
                    ></v-autocomplete>
                </div>
                <div class="col-3 col-lg-2 p-1 search-components">
                    <v-autocomplete
                        clearable
                        id="model_input"
                        name="model_input"
                        v-model="model"
                        :items="modelOptions"
                        item-title="short_desc"
                        item-value="value_id"
                        label="Modelo"
                        theme="light"
                        variant="solo"
                        :disabled="modelOptions.length === 0"
                        @update:modelValue="countResults()"
                    ></v-autocomplete>
                </div>
                <div class="col-3 col-lg-2 p-1 btn search-components">
                    <v-btn
                        v-if="!this.isAuthenticated"
                        id="filter_date_disabled"
                        label="Publicados hace"
                        size="x-large"
                        prepend-icon="mdi-clock-time-eight-outline"
                        class="text-none"
                        @click.native="this.$store.dispatch('user/setShowLoginModal', true)"
                    >
                        <span>Publicados hace</span>
                    </v-btn>
                    <v-btn
                        v-else-if="!this.isVerified"
                        id="filter_date_disabled"
                        label="Publicados hace"
                        size="x-large"
                        prepend-icon="mdi-clock-time-eight-outline"
                        class="text-none"
                        @click.native="this.$store.dispatch('user/setShowVerifyModal', true)"
                    >
                        <span>Publicados hace</span>
                    </v-btn>
                    <v-select
                        v-else
                        clearable
                        id="filter_date"
                        name="filter_date"
                        v-model="date"
                        :items="dateOptions"
                        item-title="short_desc"
                        item-value="value_id"
                        label="Publicados hace"
                        theme="light"
                        variant="solo"
                        @update:modelValue="countResults()"
                    ></v-select>
                </div>
                <div class="col-3 col-md-2 pl-1 search-components">
                    <v-badge color="red" v-if="this.nrAppliedFilters > 0">
                        <v-btn
                            size="x-large"
                            append-icon="mdi-tune-variant"
                            class="text-none mt-1"
                            @click="this.advancedFiltersAreVisible = !this.advancedFiltersAreVisible"
                        >
                            <span>Más filtros</span>
                        </v-btn>
                    </v-badge>
                    <v-btn
                        size="x-large"
                        append-icon="mdi-tune-variant"
                        class="text-none mt-1"
                        v-else
                        @click="this.advancedFiltersAreVisible = !this.advancedFiltersAreVisible"
                    >
                        <span>Más filtros</span>
                    </v-btn>
                    <v-dialog
                        id="complex_filters_overlay"
                        class="align-center justify-center filters-overlay"
                        scrollable
                        transition="dialog-bottom-transition"
                        :model-value="advancedFiltersAreVisible"
                    >
                        <v-toolbar density="compact" color="white">
                            <v-toolbar-title class="filter-top-title ml-5">Filtros avanzados</v-toolbar-title>
                            <v-btn icon @click="this.advancedFiltersAreVisible = !this.advancedFiltersAreVisible">
                                <v-icon size="small">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <div id="simple_filters_container" class="filters-container">
                            <div id="simple_form_container" class="simple-form-container">
                                <v-divider class="ml-5 mr-5"></v-divider>
                                <v-toolbar-title class="filter-title ml-5">Fecha de matriculación</v-toolbar-title>
                                <v-range-slider
                                    v-model="yearRange"
                                    :max="new Date().getFullYear()"
                                    :min="1900"
                                    :step="1"
                                    strict
                                    rounded
                                    class="align-center ml-5 mr-5"
                                    track-color="#E2BE9A"
                                    track-fill-color="#248277"
                                    thumb-color="#248277"
                                    @update:modelValue="calculateAppliedFilters()"
                                ></v-range-slider>
                                <v-row class="align-center ml-5 mr-5">
                                    <v-text-field
                                        class="align-center ml-1 mr-1"
                                        :model-value="yearRange[0]"
                                        label="Año desde"
                                        variant="outlined"
                                        @change="setValue('yearRange', 0, $event)"
                                    ></v-text-field>
                                    <v-text-field
                                        class="align-center ml-1 mr-1"
                                        :model-value="yearRange[1]"
                                        label="Año hasta"
                                        variant="outlined"
                                        @change="setValue('yearRange', 1, $event)"
                                    ></v-text-field>
                                </v-row>
                                <v-divider class="ml-5 mr-5"></v-divider>
                                <v-toolbar-title class="filter-title ml-5">Rango de precios</v-toolbar-title>
                                <v-range-slider
                                    v-model="priceRange"
                                    :max="200000"
                                    :min="0"
                                    :step="750"
                                    strict
                                    rounded
                                    class="align-center ml-5 mr-5"
                                    track-color="#E2BE9A"
                                    track-fill-color="#248277"
                                    thumb-color="#248277"
                                    @update:modelValue="calculateAppliedFilters()"
                                ></v-range-slider>
                                <v-row class="align-center ml-5 mr-5">
                                    <v-text-field
                                        class="align-center ml-1 mr-1"
                                        :model-value="priceRange[0]"
                                        label="Precio desde"
                                        variant="outlined"
                                        @change="setValue('priceRange', 0, $event)"
                                    ></v-text-field>
                                    <v-text-field
                                        class="align-center ml-1 mr-1"
                                        :model-value="priceRange[1]"
                                        label="Precio hasta"
                                        variant="outlined"
                                        @change="setValue('priceRange', 1, $event)"
                                    ></v-text-field>
                                </v-row>
                                <v-divider class="ml-5 mr-5"></v-divider>
                                <v-autocomplete
                                    id="province"
                                    name="province"
                                    v-model="province"
                                    :items="provinceOptions"
                                    item-title="short_desc"
                                    item-value="value_id"
                                    label="Provincia"
                                    theme="light"
                                    variant="solo"
                                    class="input pa-5 pb-0"
                                    clearable
                                    @update:modelValue="
                                        countResults();
                                        calculateAppliedFilters();
                                    "
                                ></v-autocomplete>
                                <v-divider class="ml-5 mr-5"></v-divider>
                                <v-toolbar-title class="filter-title ml-5">Rango de kilometraje</v-toolbar-title>
                                <v-range-slider
                                    v-model="mileageRange"
                                    :max="950000"
                                    :min="0"
                                    :step="10000"
                                    strict
                                    rounded
                                    class="align-center ml-5 mr-5"
                                    track-color="#E2BE9A"
                                    track-fill-color="#248277"
                                    thumb-color="#248277"
                                    @update:modelValue="
                                        countResults();
                                        calculateAppliedFilters();
                                    "
                                ></v-range-slider>
                                <v-row class="align-center ml-5 mr-5">
                                    <v-text-field
                                        class="align-center ml-1 mr-1"
                                        :model-value="mileageRange[0]"
                                        label="Desde"
                                        variant="outlined"
                                        @change="setValue('mileageRange', 0, $event)"
                                    ></v-text-field>
                                    <v-text-field
                                        class="align-center ml-1 mr-1"
                                        :model-value="mileageRange[1]"
                                        label="Hasta"
                                        variant="outlined"
                                        @change="setValue('mileageRange', 1, $event)"
                                    ></v-text-field>
                                </v-row>
                                <v-divider class="ml-5 mr-5"></v-divider>
                                <v-toolbar-title class="filter-title ml-5">Potencia (CV)</v-toolbar-title>
                                <v-range-slider
                                    v-model="powerRange"
                                    :max="1200"
                                    :min="0"
                                    :step="65"
                                    strict
                                    rounded
                                    class="align-center ml-5 mr-5"
                                    track-color="#E2BE9A"
                                    track-fill-color="#248277"
                                    thumb-color="#248277"
                                    @update:modelValue="
                                        countResults();
                                        calculateAppliedFilters();
                                    "
                                ></v-range-slider>
                                <v-row class="align-center ml-5 mr-5">
                                    <v-text-field
                                        class="align-center ml-1 mr-1"
                                        :model-value="powerRange[0]"
                                        label="Desde"
                                        variant="outlined"
                                        @change="setValue('powerRange', 0, $event)"
                                    ></v-text-field>
                                    <v-text-field
                                        class="align-center ml-1 mr-1"
                                        :model-value="powerRange[1]"
                                        label="Hasta"
                                        variant="outlined"
                                        @change="setValue('powerRange', 1, $event)"
                                    ></v-text-field>
                                </v-row>
                                <v-divider class="ml-5 mr-5"></v-divider>
                                <v-toolbar-title class="filter-title ml-5">Otros filtros</v-toolbar-title>
                                <v-autocomplete
                                    id="fuel_type"
                                    name="fuel_type"
                                    v-model="fuelType"
                                    :items="fuelTypeOptions"
                                    item-title="short_desc"
                                    item-value="value_id"
                                    label="Combustible"
                                    theme="light"
                                    variant="solo"
                                    class="input pa-5 pb-0"
                                    clearable
                                    @update:modelValue="
                                        countResults();
                                        calculateAppliedFilters();
                                    "
                                ></v-autocomplete>
                                <v-autocomplete
                                    id="gear_type"
                                    name="gear_type"
                                    v-model="gearType"
                                    :items="gearTypeOptions"
                                    item-title="short_desc"
                                    item-value="value_id"
                                    label="Cambio"
                                    theme="light"
                                    variant="solo"
                                    class="input pa-5 pb-0"
                                    clearable
                                    @update:modelValue="
                                        countResults();
                                        calculateAppliedFilters();
                                    "
                                ></v-autocomplete>
                            </div>
                            <div class="overlay-footer">
                                <v-btn
                                    size="x-large"
                                    density="default"
                                    prepend-icon="mdi-magnify"
                                    class="btn-primary text-none rounded-xl"
                                    @click="
                                        doSearch() && (this.advancedFiltersAreVisible = !this.advancedFiltersAreVisible)
                                    "
                                >
                                    <template v-if="this.foundAds === 0 && !this.hasModifiedSearch">Ver todo</template>
                                    <template v-else>
                                        Ver
                                        <span>{{ this.foundAds }}</span>
                                        coches
                                    </template>
                                </v-btn>
                                <v-btn
                                    size="x-large"
                                    variant="text"
                                    density="default"
                                    class="text-none"
                                    @click="this.advancedFiltersAreVisible = !this.advancedFiltersAreVisible"
                                >
                                    Cancelar
                                </v-btn>
                            </div>
                        </div>
                    </v-dialog>
                </div>
                <div class="col-3 p-0 col-lg-4">
                    <div class="submit-area">
                        <v-btn
                            size="x-large"
                            prepend-icon="mdi-magnify"
                            class="btn-primary text-none mt-1"
                            @click="doSearch()"
                        >
                            <template v-if="this.foundAds === 0 && !this.hasModifiedSearch">Ver todo</template>
                            <template v-else>
                                Ver
                                <span>{{ this.foundAds }}</span>
                                coches
                            </template>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container col-12 p-0 pr-1 order-select" v-if="!this.$store.getters['shareSearch/isInitialSearch']">
        <label for="order_by" class="col-12 pa-5 pb-0 pt-0 text-right">Ordenar</label>
        <v-select
            id="order_by"
            name="order_by"
            v-model="orderBy"
            :items="orderByOptions"
            item-title="short_desc"
            item-value="value_id"
            theme="light"
            variant="plain"
            density="compact"
            class="col-6 col-md-4 col-lg-3 col-xl-2 pb-0 pt-0 ml-auto"
            @update:modelValue="doSearch()"
            :loading="!orderByOptions"
        ></v-select>
    </div>
</template>
<script>
import { getFilterValues } from '@/application/getFilterValues/getFilterValues';
import { getValuesBasedOnParent } from '@/application/getFilterValues/getFilterValuesBasedOnParent';
import { countAnuncio } from '@/application/searchAnuncio/countAnuncio';
import { searchAnuncio } from '@/application/searchAnuncio/searchAnuncio';
import { searchLastFound } from '@/application/searchAnuncio/searchLastFound';
import { VAutocomplete } from '@/components/ac-vuetify';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { mapState } from 'vuex';
export default {
    props: ['seoBrand', 'seoModel', 'seoProvince', 'isSeoPage'],
    name: 'SearchBox',
    components: {
        VAutocomplete,
    },
    data() {
        return {
            simpleFiltersAreVisible: false,
            advancedFiltersAreVisible: false,
            isAuthenticated: this.$store.getters['user/isAuthenticated'],
            isVerified: this.$store.getters['user/isVerified'],
            isUnlocked: this.$store.getters['user/isUnlocked'],
            initialSearch: {
                make: this.seoBrand === null ? null : this.seoBrand.value_id,
                model: this.seoModel === null ? null : this.seoModel.value_id,
                province: this.seoProvince === null ? null : this.seoProvince.value_id,
                minprice: 750,
                maxprice: 200000,
                minyear: 1970,
                maxyear: new Date().getFullYear(),
                minmileage: 1,
                maxmileage: 950000,
                minpower: 1,
                maxpower: 1200,
                orderby: 34,
                page: 1,
                perpage: 20,
            },
            searchDefined: true,
            filtersApplied: {},
            nrAppliedFilters: 0,
            hasModifiedSearch: false,
            foundAds: 0,
            loadingCountFoundAds: false,
            brandOptions: [],
            modelOptions: [],
            dateOptions: [
                { value_id: 'siempre', short_desc: 'Siempre' },
                { value_id: '3d', short_desc: '3 días' },
                { value_id: '7d', short_desc: '7 días' },
                { value_id: '30d', short_desc: '30 días' },
            ],
            provinceOptions: [],
            gearTypeOptions: [],
            fuelTypeOptions: [],
            orderByOptions: [],
            brand: null,
            model: null,
            date: null,
            province: null,
            gearType: null,
            fuelType: null,
            priceRange: [750, 200000],
            yearRange: [2000, new Date().getFullYear()],
            mileageRange: [0, 950000],
            powerRange: [0, 1200],
            orderBy: null,
            page: 1,
            perpage: 20,
        };
    },
    computed: {
        ...mapState(['ads']),
        ...mapState(['shareSearch']),
        years() {
            const year = new Date().getFullYear();
            return Array.from({ length: year - 1970 }, (value, index) => year - index);
        },
    },
    async beforeMount() {
        this.brandOptions = await getFilterValues(1);
        this.provinceOptions = await getFilterValues(13);
        this.gearTypeOptions = await getFilterValues(14);
        this.fuelTypeOptions = await getFilterValues(15);
        this.orderByOptions = await getFilterValues(3);
    },
    async mounted() {
        this.$store.dispatch('ads/setLoading', true);

        this.brand = this.isInitiallySet('make') || null;
        if (this.brand) {
            await this.getModelListByBrand();
        }
        if (this.modelOptions.length > 1) {
            this.model = this.isInitiallySet('model') || null;
        }

        this.date = this.isInitiallySet('date') || null;
        this.gearType = this.isInitiallySet('geartype') || null;
        this.fuelType = this.isInitiallySet('fueltype') || null;
        this.province = this.isInitiallySet('province') || null;
        this.orderBy = this.isInitiallySet('orderby') || 34;
        this.yearRange[0] = this.isInitiallySet('minyear') || null;
        this.yearRange[1] = this.isInitiallySet('maxyear') || null;
        this.priceRange[0] = this.isInitiallySet('minprice') || null;
        this.priceRange[1] = this.isInitiallySet('maxprice') || null;
        this.mileageRange[0] = this.isInitiallySet('minmileage') || null;
        this.mileageRange[1] = this.isInitiallySet('maxmileage') || null;
        this.powerRange[0] = this.isInitiallySet('minpower') || null;
        this.powerRange[1] = this.isInitiallySet('maxpower') || null;
        this.page = this.isInitiallySet('page') || null;

        if (this.$store.getters['shareSearch/isInitialSearch'] === true || this.isSeoPage) {
            this.doRecentAnunciosSearch(this.initialSearch);
        } else {
            if (this.$store.getters['shareSearch/isSearchForced'] === true) {
                this.doSearch(this.shareSearch.searchContent);
                this.$store.dispatch('shareSearch/setForceSearch', false);
            } else {
                this.doSearch();
            }
        }
    },
    methods: {
        setValue(val, key, event) {
            this[val][key] = event.target.value;
        },
        isInitiallySet(prop) {
            var searchContent = this.shareSearch.searchContent;
            let isInitiallySet =
                typeof this.initialSearch !== 'undefined' &&
                typeof this.initialSearch[prop] !== 'undefined' &&
                this.initialSearch[prop] !== null;

            if (searchContent === null || this.isSeoPage) {
                return isInitiallySet ? this.initialSearch[prop] : false;
            }

            if (searchContent[prop] !== null) {
                return searchContent[prop];
            }

            return isInitiallySet ? this.initialSearch[prop] : false;
        },
        async search() {
            this.$store.dispatch('ads/setLoading', true);
            this.$store.dispatch('ads/setInitialSearch', false);

            let search = {
                make: this.brand,
                model: this.model,
                date: this.date,
                province: this.province,
                geartype: this.gearType,
                fueltype: this.fuelType,
                minprice: this.priceRange[0] ?? 750,
                maxprice: this.priceRange[1] ?? 200000,
                minyear: this.yearRange[0] ?? 2000,
                maxyear: this.yearRange[1] ?? new Date().getFullYear(),
                minmileage: this.mileageRange[0] ?? 0,
                maxmileage: this.mileageRange[1] ?? 950000,
                minpower: this.powerRange[0] ?? 0,
                maxpower: this.powerRange[1] ?? 1200,
                orderby: this.orderBy ?? 34,
                page: this.page,
                perpage: this.perpage,
            };
            await searchAnuncio(search, this.brandOptions, this.modelOptions, this.provinceOptions).catch((err) => {
                this.$store.dispatch('ads/setErrorFlag', true);
            });

            this.$store.dispatch('shareSearch/setSearchContent', search);
            this.$store.dispatch('ads/setLoading', false);
        },
        async countResults(search = null) {
            if (!this.loadingCountFoundAds) {
                this.loadingCountFoundAds = true;
            }

            if (!search) {
                var search = {
                    make: this.brand,
                    model: this.model,
                    date: this.date,
                    province: this.province,
                    geartype: this.gearType,
                    fueltype: this.fuelType,
                    minprice: this.priceRange[0] ?? 750,
                    maxprice: this.priceRange[1] ?? 200000,
                    minyear: this.yearRange[0] ?? 2000,
                    maxyear: this.yearRange[1] ?? new Date().getFullYear(),
                    minmileage: this.mileageRange[0] ?? 0,
                    maxmileage: this.mileageRange[1] ?? 950000,
                    minpower: this.powerRange[0] ?? 0,
                    maxpower: this.powerRange[1] ?? 1200,
                    orderby: this.orderBy ?? 34,
                    page: this.page,
                    perpage: this.perpage,
                };
            }
            var countResponse = await countAnuncio(search);
            this.foundAds = countResponse.count;
            this.loadingCountFoundAds = false;
            this.hasModifiedSearch = true;
        },
        calculateAppliedFilters() {
            for (let key in this.initialSearch) {
                if (this.initialSearch[key] !== this[key]) {
                    if (!this.filtersApplied[key]) {
                        this.filtersApplied[key] = true;
                        this.nrAppliedFilters++;
                    }
                } else {
                    this.filtersApplied[key] = false;
                }
            }
        },
        async doSearch() {
            this.$store.dispatch('ads/setLastPage', false);
            this.initialSearch = {};
            this.page = 1;
            this.search();
        },
        async loadMore() {
            this.page = this.page + 1;
            this.search();
        },
        async doRecentAnunciosSearch(initialSearch) {
            await searchLastFound(initialSearch).catch((err) => {
                this.$store.dispatch('ads/setErrorFlag', true);
            });
            this.$store.dispatch('ads/setLoading', false);
        },
        async getModelListByBrand() {
            this.model = null;
            this.modelOptions = [];

            if (this.brand === null) {
                return;
            }

            this.modelOptions = await getValuesBasedOnParent(2, this.brand);
        },
        getShortDesc(id, haystack) {
            let obj = haystack.find((o) => o.value_id === id);
            return obj ? obj.short_desc : false;
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.register-cta {
    padding: 10px;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5rem;
    border: 1px solid $gray-10;
    background-color: $gray-20;
    p {
        padding: 0px 10px;
        font-size: 0.8em;
        text-align: justify;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .pop-up-image {
        height: auto;
        width: auto;
        max-width: 100px;
        max-height: 400px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
    }
    .cta-components {
        button {
            margin-bottom: 15px;
            letter-spacing: 0.5px;
            font-size: 13px;
            height: 56px !important;
            border-radius: $border-radius;
            span {
                margin: 1px;
                font-size: 14px;
                font-weight: bold;
            }
        }
        .btn-primary {
            background-color: $secondary;
            color: $white;
        }
    }
}
.main-search-toggle-container {
    position: relative;
    float: left;
    z-index: 15;
    margin-top: -100px;
    padding: 0;
    z-index: 0;
    .main-search-toggle {
        width: 350px;
        height: 60px;
        background-color: $white;
        overflow: hidden;
        margin: auto;
        .simple-search-toggle {
            position: relative;
            float: left;
            width: 290px;
            height: 60px;
            .icon {
                position: relative;
                float: left;
                width: 54px;
                height: 54px;
                margin-top: 3px;
                color: $secondary !important;
                i {
                    width: 100%;
                    height: 100%;
                    svg {
                        height: 25px;
                        width: 25px;
                        margin: auto;
                    }
                }
            }
            p {
                position: relative;
                float: left;
                width: 230px;
                height: 12px;
                margin: 0;
                margin-top: 10px;
                font-size: 12px;
                color: $gray-2;
            }
            p:first-of-type {
                font-weight: bold;
                font-size: 14px;
                color: $gray-0;
            }
        }
        .complex-search-toggle {
            position: relative;
            float: right;
            width: 60px;
            height: 60px;
            padding: 8px;
            color: $secondary;
            .button {
                border-radius: $border-radius-deep;
                margin: auto;
                width: 44px;
                height: 44px;
                border: 1px solid $gray-10;
                i {
                    margin-top: 12px;
                }
            }
        }
    }
    //md
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        .main-search-toggle {
            width: 450px !important;
            .simple-search-toggle {
                width: 390px !important;
            }
        }
    }
    //lg
    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
        .main-search-toggle {
            width: 550px !important;
            .simple-search-toggle {
                width: 490px !important;
            }
        }
    }
    @media (min-width: $breakpoint-xl) {
        .main-search-toggle {
            display: none;
        }
    }
}
.order-select {
    position: relative;
    font-size: 13px !important;
    font-weight: bold;
    color: $gray;
    .v-field__input,
    .v-field__append-inner {
        margin-top: -10px;
        margin-left: -10px;
    }
    .v-field__input {
        font-size: 12px !important;
    }
    .v-select .v-select__selection-text {
        width: 100%;
        text-align: right;
    }
    .v-select__selection {
        width: 100%;
    }
    .v-field__append-inner {
        margin-left: -25px;
    }
}
.filters-overlay {
    .simple-form-container {
        min-height: 80vh;
    }
    .v-overlay__content {
        position: absolute;
        width: 100%;
        font-family: $main-font;
        max-width: 100%;
        max-height: 95%;
        bottom: 0;
        margin: 0 !important;
        background-color: $white;
        border-top-left-radius: $border-radius-deep;
        border-top-right-radius: $border-radius-deep;
        .v-toolbar {
            border-top-left-radius: $border-radius-deep;
            border-top-right-radius: $border-radius-deep;
        }
        .filters-container {
            overflow-x: hidden;
            overflow-y: scroll;
            padding-bottom: 100px;
            height: 100%;
            .other-filter-button {
                position: relative;
                float: right;
                height: 55px !important;
                letter-spacing: 0.5px;
                font-size: 13px;
                border-radius: $border-radius-deep !important;
                span {
                    margin: 1px;
                    margin-right: 5px;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            .time-button {
                font-family: $main-font;
                letter-spacing: 0.5px;
                position: relative;
                float: right;
                color: $gray-2;
                width: calc(100% - 40px);
                justify-content: left;
                padding-left: 15px;
                .v-btn__prepend {
                    display: none;
                }
                .v-btn__content {
                    text-align: left;
                    font-size: 16px;
                }
                .v-btn__append {
                    position: absolute;
                    margin-right: calc(-100vw + 238px);
                    right: 0;
                    font-size: 7px;
                }
            }
        }
        .filter-top-title {
            color: $gray-0;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.2px;
        }
        .filter-title {
            color: $gray-0;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.2px;
        }
        .overlay-footer {
            background-color: $white;
            width: 100%;
            padding: 10px;
            position: fixed;
            bottom: 0;
            @include aux-box-shadow;
            button {
                position: relative;
                float: right;
                letter-spacing: 0.5px;
                font-size: 13px;
                margin-left: 10px;
                span {
                    margin: 1px;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            .btn-primary {
                background-color: $secondary;
                color: $white;
            }
        }
    }
    @media (min-width: $breakpoint-md) {
        .v-overlay__content {
            position: relative;
            border-radius: $border-radius-deep;
            max-width: 800px;
            .desktop-filters-container {
                overflow-x: hidden;
                padding-bottom: 80px;
                height: 100%;
            }
            .overlay-footer {
                border-bottom-left-radius: $border-radius-deep;
                border-bottom-right-radius: $border-radius-deep;
            }
        }
    }
}
.main-desktop-search-toggle {
    display: none;
    @media (min-width: $breakpoint-lg) {
        display: block !important;
    }
}
.search-components {
    .v-field {
        height: 66px !important;
        font-size: 13px;
        border-radius: $border-radius-deep !important;
    }
    button {
        height: 66px !important;
        letter-spacing: 0.5px;
        font-size: 13px;
        border-radius: $border-radius-deep !important;
        span {
            margin: 1px;
            margin-right: 5px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}
.submit-area {
    button {
        position: relative;
        float: right;
        letter-spacing: 0.5px;
        font-size: 13px;
        height: 66px !important;
        span {
            margin: 1px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .btn-primary {
        background-color: $secondary;
        color: $white;
    }
}
</style>
