import { getApiParserBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

async function track(event, data) {
    try {
        const response = await HttpClient.post(`${getApiParserBaseUrl()}/track/${event}`, { data });

        return response.data;
    } catch (err) {
        throw err;
    }
}
export { track };
