import { sendBusinessContactMessage } from '@/infrastructure/services/business';

import store from '@/infrastructure/persistence/store/vuex';

async function sendContactMessage(messageInfo) {
    const vuexstore = store.repository;
    try {
        const response = await sendBusinessContactMessage(messageInfo);

        if (response.status === 200 || response.status === 201) {
            vuexstore.dispatch(
                'feedbackInterface/setSuccesfulResponse',
                'Mensaje enviado correctamente. Se pondrán en contacto contigo lo antes posible.',
            );

            return true;
        }

        if (response.status >= 500) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Ha habido un error inesperado al enviar el mensaje. Vuelve a intentarlo más tarde.',
            );
            return false;
        }

        vuexstore.dispatch('feedbackInterface/setErrorResponse', response.data.message);
        return false;
    } catch (error) {
        throw Error(error.message);
    }
}

export { sendContactMessage };
