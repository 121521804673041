import { searchSingleAdService } from '@/infrastructure/services/searchAnuncioService';
async function searchSingleAnuncio(id) {
    try {
        var ad = await searchSingleAdService(id);

        ad.title = ad.title.charAt(0).toUpperCase() + ad.title.slice(1);
        var date = new Date(ad.createdAt);
        ad.createdAt = date.toLocaleDateString();

        return ad;
    } catch (error) {
        throw Error(error.message);
    }
}

export { searchSingleAnuncio };
