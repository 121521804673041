export default [
    {
        designId: 'pictureBanner',
        contentId: 'ahorra_3000_fiat_compu',
        pictureSrc: '/NeedCarHelp/banner/Bann_compu_1000x300_ahorra_3000_euros.jpg',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=compu&utm_campaign=ahorra_3',
    },
    {
        designId: 'pictureBanner',
        contentId: 'ahorra_tiempo_dinero_problemas_compu',
        pictureSrc: '/NeedCarHelp/banner/Bann_compu_1000x300_ahorra_tiempo_dinero.jpg',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=compu&utm_campaign=tiempo',
    },
    {
        designId: 'pictureBanner',
        contentId: 'El_secreto_audi_compu',
        pictureSrc: '/NeedCarHelp/banner/Bann_compu_1000x300_secreto_no_afeitada_km.jpg',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=compu&utm_campaign=afe_km',
    },
    {
        designId: 'pictureBanner',
        contentId: 'no_compres_preocup_compu',
        pictureSrc: '/NeedCarHelp/banner/Bann_compu_1000x300_no_compres_preocup.jpg',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=compu&utm_campaign=no_compres',
    },
    {
        designId: 'pictureBanner',
        contentId: 'que_no_te_lien_compu',
        pictureSrc: '/NeedCarHelp/banner/Bann_compu_1000x300_que_no_te_lien.jpg',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=compu&utm_campaign=que_no',
    },
    {
        designId: 'pictureBanner',
        contentId: 'sueno_vs_pesadilla_compu',
        pictureSrc: '/NeedCarHelp/banner/Bann_compu_1000x300_sueno_vs_pesadilla.jpg',
        customerName: 'NeedCarHelp',
        buttonUrl: 'https://needcarhelp.es/?utm_source=alertacoches&utm_medium=compu&utm_campaign=pesadilla',
    },
];
