import { addFavourite, removeFavourite } from '@/infrastructure/services/favouriteHandler';

import store from '@/infrastructure/persistence/store/vuex';

async function toggleFavouriteStatus(id) {
    const vuexstore = store.repository;
    try {
        if (vuexstore.state.user.favourites.includes(id)) {
            var response = await removeFavourite(id);
            vuexstore.dispatch('user/removeFavourite', id);
        } else {
            var response = await addFavourite(id);
            vuexstore.dispatch('user/addFavourite', id);
        }

        return response;
    } catch (error) {
        return 'No hemos podido gestionar el cambio. Inténtalo más tarde.';
    }
}

export { toggleFavouriteStatus };
