import { createStore } from 'vuex';

import user from './modules/user';
import ads from './modules/ads';
import similarAds from './modules/similarAds';
import favourites from './modules/favourites';
import shareSearch from './modules/shareSearch';
import feedbackInterface from './modules/feedbackInterface';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    modules: {
        user,
        ads,
        favourites,
        similarAds,
        shareSearch,
        feedbackInterface,
    },
    plugins: [createPersistedState()],
    strict: true,
});
