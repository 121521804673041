import { getBusinessReviews } from '@/infrastructure/services/business';

async function getCustomerReviews(businessSlug) {
    try {
        const reviews = await getBusinessReviews(businessSlug);

        return reviews;
    } catch (error) {
        throw Error(error.message);
    }
}

export { getCustomerReviews };
