<template>
    <div class="page" ref="favourites">
        <SimpleNavBar />
        <div class="container-fluid">
            <div class="container col-12 col-md-8">
                <h1 class="profile-header">Tus favoritos</h1>
                <div class="container p-0 favourite-container">
                    <div class="row p-3 d-flex justify-content-left">
                        <template v-if="favourites.loading">
                            <SkeletonAdCard v-for="n in 10" :key="n" />
                        </template>
                        <template v-else-if="typeof favourites.items == 'undefined' || favourites.items.length == 0">
                            <img
                                class="mb-4 favourite-section-image"
                                src="@/assets/images/favourite_section.svg"
                                alt="Añade favoritos a tu lista para verlos aquí."
                            />
                            <p>¡Añade nuevos favouritos para verlos aquí!</p>
                        </template>
                        <template v-else>
                            <SimpleCard
                                v-for="ad in this.favourites.items"
                                :key="ad.id"
                                :card="ad"
                                :isFavourite="isFavourite(ad)"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import Footer from '@/components/structural/Footer.vue';
import SimpleCard from '@/components/cards/SimpleCard.vue';
import SkeletonAdCard from '@/components/cards/SkeletonAdCard.vue';
import { getListOfFavourites } from '@/application/favourite/getFavourites';
import { mapState } from 'vuex';
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';

export default {
    components: {
        SimpleNavBar,
        SimpleCard,
        SkeletonAdCard,
        Footer,
    },
    computed: {
        ...mapState(['favourites', 'user']),
    },
    async mounted() {
        this.$store.dispatch('favourites/setLoading', true);
        await this.getFavourites();
    },
    methods: {
        async getFavourites() {
            await getListOfFavourites().catch((err) => {
                this.$store.dispatch('favourites/setErrorFlag', true);
            });
            this.$store.dispatch('favourites/setLoading', false);
        },
        isFavourite(ad) {
            return this.user.favourites.includes(ad.id);
        },
    },
    setup() {
        const siteData = reactive({
            title: 'AlertaCoches - Mis favoritos',
            description: 'Aquí puedes ver tus anuncios favoritos',
        });

        useHead({
            title: computed(() => siteData.title),
            meta: [{ name: 'description', content: computed(() => siteData.description) }],
        });
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.favourite-container {
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 120px !important;
    width: 100%;
    p {
        font-size: 1.2rem;
        color: $gray-2;
        font-weight: bold;
    }
    .favourite-section-image {
        height: auto;
        margin: 0 auto;
        display: block;
        padding: 20px 25%;
        border: 1px solid $gray-15;
        background-color: $gray-20;
        border-radius: $border-radius-deep;
    }
}
.favourite-components {
    .v-field {
        height: 66px !important;
        font-size: 13px;
        border-radius: $border-radius-deep !important;
    }
    button {
        letter-spacing: 0.5px;
        font-size: 13px;
        height: 66px !important;
        border-radius: $border-radius;
        span {
            margin: 1px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .btn-primary {
        background-color: $secondary;
        color: $white;
    }
}
</style>
