export default {
    title: {
        home: 'AlertaCoches | Coches de segunda mano - Busca anuncios en diferentes portales de compraventa',
    },
    cv: '{value}CV',
    km: '{value}kms',
    gear_type: {
        at: 'Automático',
        manual: 'Manual',
    },
    fuel_type: {
        diesel: 'Diesel',
        gasoline: 'Gasolina',
        electric: 'Eléctrico/Híbrido',
    },
    brand_label: 'Marca',
    model_label: 'Modelo',
    state_label: 'Provincia',
    price_label: 'Precio',
    mileage_label: 'Kilómetros',
    year_label: 'Año de matriculación',
    year_from: 'Año desde',
    year_to: 'Año hasta',
    from: 'Desde',
    to: 'Hasta',
    other_filters: 'Otros filtros',
    order_label: 'Ordenar por',
    gear_type_label: 'Tipo de cambio',
    fuel_type_label: 'Combustible',
    select_brand_warning: 'Selecciona una marca',
    propetary_copyright: ' de sus respectivos propietarios',
    the_team: 'El equipo',
    faq: 'Preguntas frecuentes',
    legal: 'Legal',
    legal_policy: 'Aviso legal',
    priv_policy: 'Política de privacidad',
    cookies_policy: 'Política de cookies',
    contact: 'Contacto',
    what_if: '¿Y si?',
    find_car: 'Encuentra el coche que buscas',
    sorry: '¡Lo siento!',
    oops: 'Ups...',
    any: 'Cualquiera',
    share: 'Compartir',
    search: 'Buscar',
    share_search: 'Compartir búsqueda',
    find_another: 'Buscar otro modelo',
    no_images: 'Este anuncio no tiene imagenes :(',
    in_one_place:
        '¿Y si pudieras buscar <strong>desde un único lugar</strong> cualquier anuncio de <strong>coches de segunda mano</strong>, independientemente del portal en que estén anunciados?',
    try_another_search: 'Mientras tanto, ¿porqué no pruebas con otra búsqueda?',
    no_results_explanation:
        'Parece que los anuncios están jugando al escondite. ¿porque no pruebas modificando algún filtro? <br> También puedes buscar coches de ocasión con los filtros de marcas que te ponemos a continuación.',
    error_explanation:
        'Parece que algo está roto. Nuestros técnicos ya se han puesto manos a la obra con el superglue! <br> Esperamos que pronto estés disfrutando de nuevo de AlertaCoches.es',
    found_for_you: 'Lo que hemos encontrado para ti',
    main_brands_title: 'Las marcas más buscadas',
    second_hand_car_title: 'Coches de ocasión recientes',
    main_headline: '¿Buscas coche de segunda mano?',
    main_models_title: 'Los modelos más buscados',
    found_for_you_secondary: 'Anuncios de todas las fuentes en base a tus filtros.',
    second_hand_car_title_secondary: 'Los últimos anuncios que hemos encontrado.',
    main_brands_title_secondary: '¡Sólo haz clic y encuentra tu futuro coche!',
    main_models_title_secondary: '¿Cuál es tu modelo preferido?',
    all_fuel_types: 'Combustibles',
    all_gear_types: 'Tipos de cambio',
    show_more: 'Ver más',
    search_again: 'Buscar otra cosa',
    number_of_sources_label: 'Número de fuentes',
    number_of_sources: '6',
    number_of_ads_label: 'Número de anuncios',
    number_of_ads: '8,4M+',
    update_rate_msg: 'Que actualizamos cada 6 horas.',
    source_list_msg:
        'Puedes buscar en Wallapop, Coches.com, AutoScout24, Motor.es, Clicars y AutoHero simultáneamente.',
    button_link_to_source: 'Enlace para ver el anuncio en la fuente',
    car_info_header: 'Información del coche',
    other_similar_cars_header: 'Coches similares a este',
    no_similar_ads_found: 'No hemos encontrado coches similares a este 🙁',
    not_available: 'No disponible',
    buy_online: 'Compra online',
    book_online: 'Reserva online',
    all_provinces: 'Todas las provincias',
    location_online: 'Compra online. Te lo llevan a tu domicilio.',
    number_of_brands_label: 'Total de marcas',
    number_of_brands: '41',
    brand_msg: 'De las principales marcas.',
    number_of_models_label: 'Total de modelos',
    number_of_models: '692',
    model_msg: 'Los más vendidos en el mercado de segunda mano de España',
    find_it: '¡Encuentralo ya!',
};
