<template>
    <div ref="cookieConsent" class="cookieConsent"></div>
</template>
<script>
import cookieconsent from 'vanilla-cookieconsent';
import { useState } from 'vue-gtag-next';
export default {
    name: 'CookieConsent',
    props: {
        options: {
            type: Object,
            required: false,
            default: {},
        },
    },
    data() {
        return {
            cc: null,
            defaultOptions: {
                autorun: true,
                delay: 0,
                current_lang: 'es',
                autoclear_cookies: true,
                cookie_expiration: 365,
                cookie_domain: import.meta.env.VITE_COOKIE_DOMAIN,
                gui_options: {
                    consent_modal: {
                        layout: 'cloud',
                        position: 'bottom',
                        transition: 'slide',
                    },
                    settings_modal: {
                        layout: 'bar',
                        transition: 'slide',
                        position: 'right',
                    },
                },
                languages: {
                    es: {
                        consent_modal: {
                            title: 'Sí, nosotros también usamos cookies',
                            description:
                                '<strong>AlertaCoches</strong> usa cookies para asegurar el correcto funcionamiento del portal, así como entender qué uso le das para poder mejorar constantemente nuestros servicios. Esto último sólo se hará si das tu consentimiento. <a aria-label="Política de cookies" class="cc-link" href="https://about.alertacoches.es/politica-cookies" target="_blank">Leer más</a>',
                            primary_btn: {
                                text: 'Aceptar todo',
                                role: 'accept_all',
                            },
                            secondary_btn: {
                                text: 'Configuración',
                                role: 'settings',
                            },
                        },
                        settings_modal: {
                            title: 'Configuración de las cookies',
                            save_settings_btn: 'Guardar configuración',
                            accept_all_btn: 'Aceptar todas',
                            cookie_table_headers: [
                                { col1: 'Nombre' },
                                { col2: 'Propietario' },
                                { col3: 'Duración' },
                                { col4: 'Tipo cookie' },
                                { col5: 'Finalidad' },
                            ],
                            blocks: [
                                {
                                    title: 'Uso de cookies',
                                    description:
                                        'Se utilizan cookies para asegurar las funcionalidades básicas del sitio y para mejorar la experiencia online. Puedes seleccionar qué cookies según categoría.',
                                },
                                {
                                    title: 'Cookies técnicas',
                                    description:
                                        'Estas cookies son necesarias. El portal no funcionaría correctamente sin ellas.',
                                    toggle: {
                                        value: 'necessary_cookies',
                                        enabled: true,
                                        readonly: true,
                                    },
                                    cookie_table: [
                                        {
                                            col1: 'alertacoches_session',
                                            col2: 'Propia',
                                            col3: '2 horas',
                                            col4: 'Cookie de sesión',
                                            col5: 'Identificar la sesión de un usuario en particular en el sistema.',
                                        },
                                        {
                                            col1: 'XSRF-TOKEN',
                                            col2: 'Propia',
                                            col3: '2 horas',
                                            col4: 'Cookie técnica',
                                            col5: 'Corroborar que el usuario es una personal física.',
                                        },
                                    ],
                                },
                                {
                                    title: 'Cookies analíticas',
                                    description:
                                        'Estas cookies nos permiten saber cómo se usa el portal y qué páginas has visitado. Todos los datos son anónimos: no podemos usarlos para identificarte personalmente.',
                                    toggle: {
                                        value: 'analytics_cookies',
                                        enabled: true,
                                        readonly: false,
                                    },
                                    cookie_table: [
                                        {
                                            col1: '_ga',
                                            col2: 'Google',
                                            col3: '2 años',
                                            col4: 'Cookies de análisis o medición',
                                            col5: 'Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web.',
                                        },
                                        {
                                            col1: '_ga_*',
                                            col2: 'Google',
                                            col3: '2 años',
                                            col4: 'Cookies de análisis o medición',
                                            col5: 'Conserva el estado de la sesión.',
                                        },
                                        {
                                            col1: '_gid',
                                            col2: 'Google',
                                            col3: '1 day',
                                            col4: 'Cookies de análisis o medición',
                                            col5: 'Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web.',
                                        },
                                        {
                                            col1: '_gid',
                                            col2: 'Google',
                                            col3: '1 day',
                                            col4: 'Cookies de análisis o medición',
                                            col5: 'Esta cookie es utilizada por Google Analytics para comprender la interacción del usuario con el sitio web.',
                                        },
                                        {
                                            col1: 'SubscriberCookie_*',
                                            col2: 'Google',
                                            col3: '1 year',
                                            col4: 'Cookies de análisis o medición',
                                            col5: 'Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web.',
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            },
        };
    },
    mounted() {
        this.cc = initCookieConsent(this.$refs.cookieConsent);
        this.run();
    },
    methods: {
        run() {
            const options = Object.assign(this.defaultOptions, this.options, {
                onAccept: this.onAccept,
                onChange: this.onChange,
                onFirstAction: this.onFirstAction,
            });
            this.cc.run(options);
        },
        onAccept() {
            if (this.cc.allowedCategory('analytics_cookies')) {
                const { isEnabled } = useState();
                isEnabled.value = true;
            }
        },
        onChange() {},
        onFirstAction() {},
    },
};
</script>
<style src="./cookieconsent.css"></style>
<style lang="scss">
:root {
    --cc-bg: #fff;
    --cc-text: #14746f;
    --cc-btn-primary-bg: #14746f;
    --cc-btn-primary-text: var(--cc-bg);
    --cc-btn-primary-hover-bg: #036666;
    --cc-btn-secondary-bg: #eaeff2;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #d8e0e6;
    --cc-toggle-bg-off: #919ea6;
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #d5dee2;
    --cc-toggle-knob-bg: #fff;
    --cc-toggle-knob-icon-color: #ecf2fa;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: #f0f4f7;
    --cc-cookie-category-block-bg-hover: #e9eff4;
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e9edf2;
    --cc-overlay-bg: rgba(4, 6, 8, 0.85);
    --cc-webkit-scrollbar-bg: #cfd5db;
    --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.c_darkmode {
    --cc-bg: #181b1d;
    --cc-text: #d8e5ea;
    --cc-btn-primary-bg: #a6c4dd;
    --cc-btn-primary-text: #000;
    --cc-btn-primary-hover-bg: #c2dff7;
    --cc-btn-secondary-bg: #33383c;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #3e454a;
    --cc-toggle-bg-off: #667481;
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #454c54;
    --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
    --cc-toggle-knob-icon-color: var(--cc-bg);
    --cc-block-text: #b3bfc5;
    --cc-cookie-category-block-bg: #23272a;
    --cc-cookie-category-block-bg-hover: #2b3035;
    --cc-section-border: #292d31;
    --cc-cookie-table-border: #2b3035;
    --cc-webkit-scrollbar-bg: #667481;
    --cc-webkit-scrollbar-bg-hover: #9199a0;
}
</style>
